import type { MRT_Localization as Localization } from 'material-react-table';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function useMRTLocalization(): Partial<Localization> {
    const intl = useIntl();

    return useMemo((): Partial<Localization> => ({
        clearSort: intl.formatMessage({ id: 'mrt.clear-sort' }),
        columnActions: intl.formatMessage({ id: 'mrt.column-actions' }),
        goToFirstPage: intl.formatMessage({ id: 'mrt.go-to-first-page' }),
        goToLastPage: intl.formatMessage({ id: 'mrt.go-to-last-page' }),
        goToNextPage: intl.formatMessage({ id: 'mrt.go-to-next-page' }),
        goToPreviousPage: intl.formatMessage({ id: 'mrt.go-to-previous-page' }),
        noRecordsToDisplay: intl.formatMessage({ id: 'mrt.no-records-to-display' }),
        of: intl.formatMessage({ id: 'mrt.of' }),
        rowsPerPage: intl.formatMessage({ id: 'mrt.rows-per-page' }),
        sortByColumnAsc: intl.formatMessage({ id: 'mrt.sort-by-column-asc' }),
        sortByColumnDesc: intl.formatMessage({ id: 'mrt.sort-by-column-desc' }),
        sortedByColumnAsc: intl.formatMessage({ id: 'mrt.sorted-by-column-asc' }),
        sortedByColumnDesc: intl.formatMessage({ id: 'mrt.sorted-by-column-desc' }),
        toggleSelectAll: intl.formatMessage({ id: 'mrt.toggle-select-all' }),
        toggleSelectRow: intl.formatMessage({ id: 'mrt.toggle-select-row' }),
        unsorted: intl.formatMessage({ id: 'mrt.unsorted' }),
    }), [intl]);
}
