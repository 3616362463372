import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DayOfWeek } from '../../../generated/poi-service-internal';

interface Props {
    day: DayOfWeek;
}

const DayOfWeekText: React.FC<Props> = ({ day }) => {
    const intl = useIntl();

    if (day === DayOfWeek.HOL) {
        return <FormattedMessage id="poi-form.day-of-week.holiday" />;
    }

    const date = new Date(0);
    date.setDate(getDateForDayOfWeek(day));

    const text = intl.formatDate(date, { weekday: 'long', timeZone: 'UTC' });
    return (
        <>
            {capitalize(text)}
        </>
    );
};

function capitalize(text: string) {
    if (text === '') {
        return '';
    }
    return text[0].toUpperCase() + text.substring(1);
}

function getDateForDayOfWeek(day: DayOfWeek): number {
    switch (day) {
        case DayOfWeek.MON:
            return 5;
        case DayOfWeek.TUE:
            return 6;
        case DayOfWeek.WED:
            return 7;
        case DayOfWeek.THU:
            return 1;
        case DayOfWeek.FRI:
            return 2;
        case DayOfWeek.SAT:
            return 3;
        case DayOfWeek.SUN:
            return 4;
        default:
            return 0;
    }
}

export default DayOfWeekText;
