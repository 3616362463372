import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useContext } from 'react';
import { ApiClientContext } from '../../providers/ApiClientWrapper';
import useDeferredAsync from '../../hooks/useDeferredAsync';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import type { ExportPoisRequest } from '../../generated/poi-service-internal';
import { PoisContext } from './PoisContext';

const ExportPoisButton: React.FC = () => {
    const apiClient = useContext(ApiClientContext);
    const { selectedType } = useContext(PoisContext);

    const exportPois = useCallback(async (variables: ExportPoisRequest, controller: AbortController): Promise<{}> => {
        const response = await apiClient.exportPoisRaw(variables, controller);
        await downloadResponse(response.raw);
        return {};
    }, [apiClient]);

    const [result, executeExportPois] = useDeferredAsync(exportPois);

    return (
        <>
            <Button
                variant="text"
                onClick={() => {
                    executeExportPois({ type: selectedType });
                }}
            >
                <FormattedMessage id="poi-editor.export" />
            </Button>
            <MutationProgressDialog mutationResult={result} />
        </>
    );
};

async function downloadResponse(response: Response) {
    const blob = await response.blob();

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'export.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export default ExportPoisButton;
