import React from 'react';
import type { UserFavoriteNameFragment } from '../../../graphql/graphql-typings';

interface Props {
    favorite: UserFavoriteNameFragment;
}

const FavoriteNameRenderer: React.FC<Props> = ({ favorite }) => (
    <>
        {favorite.name}
        {' '}
        (
        {favorite.entityId}
        )
    </>
);

export default FavoriteNameRenderer;
