import type { UserAutocompleteOptionFragment } from '../../graphql/graphql-typings';
import type { MessageTranslation } from './MessageTranslationForm';

export enum AddresseeType {
    ALL = 'ALL',
    SPECIFIC = 'SPECIFIC'
}

export interface SendMessageFormValue {
    addresseeType: AddresseeType;
    addresseeUser: UserAutocompleteOptionFragment | null;
    link: string;
    image: string;
    hu: MessageTranslation;
    en: MessageTranslation;
}
