import { DayOfWeek, PoiStatus } from '../../../generated/poi-service-internal';

export const DAYS: DayOfWeek[] = [
    DayOfWeek.MON,
    DayOfWeek.TUE,
    DayOfWeek.WED,
    DayOfWeek.THU,
    DayOfWeek.FRI,
    DayOfWeek.SAT,
    DayOfWeek.SUN,
    DayOfWeek.HOL,
];

export const POI_OPERATIONAL_VALUES = [true, false];

export const POI_STATUSES = [PoiStatus.DRAFT, PoiStatus.PUBLISHED];
