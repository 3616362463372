import { useRef, useState } from 'react';

// same as useState, but value is reset to default when dependency changes.
export default function useStateWithReset<T, D>(defaultValue: T, dependency: D): [value: T, setValue: (value: T) => void] {
    const [value, setValue] = useState([defaultValue]);

    const depRef = useRef(dependency);
    if (depRef.current !== dependency) {
        value[0] = defaultValue;
        depRef.current = dependency;
    }

    return [value[0], (newValue: T) => {
        setValue([newValue]);
    }];
}
