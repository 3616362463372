import maplibregl from 'maplibre-gl';
import type { ReactElement, Ref } from 'react';
import React, { forwardRef } from 'react';
import type { MapRef } from 'react-map-gl';
import Map from 'react-map-gl';
import APP_CONFIG from '../config';

import 'maplibre-gl/dist/maplibre-gl.css';

type Props = Parameters<typeof Map>[0];

const MaplibreMap = forwardRef((props: Props, ref: Ref<MapRef>): ReactElement => (
    <Map
        ref={ref}
        mapLib={maplibregl}
        mapStyle={APP_CONFIG.POI_EDITOR_MAP_STYLE}
        {...props}
    />
));

export default MaplibreMap;
