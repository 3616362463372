import Link from '@mui/material/Link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { UserLinkFragment } from '../graphql/graphql-typings';

interface Props {
    user: UserLinkFragment;
}

const UserLink: React.FC<Props> = ({ user }) => (
    <Link underline="hover" to={`/users/${user.id}`} component={RouterLink}>
        {user.displayName}
    </Link>
);

export default UserLink;
