import type { AppVersionAndApiKeyFragment, TranslatedStringInput } from './graphql/graphql-typings';

// eslint-disable-next-line import/prefer-default-export
export function translatedStrings<T extends { [P in K]: string }, K extends string>(
    value: { hu: T; en: T },
    field: K,
): TranslatedStringInput[] {
    return (['hu', 'en'] as const).map<TranslatedStringInput>(locale => ({
        locale,
        text: value[locale][field],
    }));
}

export function affectedVersionsToString(affectedVersions: AppVersionAndApiKeyFragment[]): string {
    return affectedVersions
        .map(version => version.appVersion !== null ? `${version.apiKey || ''}:${version.appVersion}` : version.apiKey)
        .join(',');
}

export function classNames(...classes: any[]): string {
    return classes.filter(Boolean).join(' ');
}
