import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedTime } from 'react-intl';
import type { SubscriptionTimeFragment } from '../../../../graphql/graphql-typings';

interface Props {
    times: SubscriptionTimeFragment[];
}

const SubscriptionTimeRenderer: React.FC<Props> = ({ times }) => (
    <>
        {times.map((time, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={i} variant="body2">
                {time.day}
                {' '}
                <FormattedTime value={time.from * 1000} timeZone="UTC" />
                -
                <FormattedTime value={time.to * 1000} timeZone="UTC" />
            </Typography>
        ))}
    </>
);

export default SubscriptionTimeRenderer;
