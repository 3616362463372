import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import type { ImportError } from '../../generated/poi-service-internal';

interface Props {
    errors: ImportError[];
}

const ImportPoisErrors: React.FC<Props> = ({ errors }) => (
    <>
        <DialogTitle sx={{ p: 0, color: theme => theme.palette.error.main }}>
            <FormattedMessage id="poi-editor.import.failed-title" />
        </DialogTitle>
        <ul>
            {errors.map((importError, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                    {importError.type === 'IMPORT_ERROR_IN_FILE' && (
                        <FormattedMessage id="poi-editor.import.error.file" values={{ errorCode: importError.errorCode }} />
                    )}
                    {importError.type === 'IMPORT_ERROR_IN_ROWS' && (
                        <FormattedMessage
                            id="poi-editor.import.error.row"
                            values={{
                                row: <strong>{importError.row}</strong>,
                                column: <strong>{importError.column}</strong>,
                                value: <em>{importError.value}</em>,
                            }}
                        />
                    )}
                </li>
            ))}
        </ul>
    </>
);

export default ImportPoisErrors;
