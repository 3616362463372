import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import type { SendMessageFormValue } from './SendMessageForm';
import UserAutocomplete from './UserAutocomplete';

interface Props {
    disabled: boolean;
}

const AddresseeUserAutocomplete: React.FC<Props> = ({ disabled }) => {
    const { control, formState: { errors } } = useFormContext<SendMessageFormValue>();
    const { field: { onChange: onUserChange, value: user, onBlur, ref } } = useController<SendMessageFormValue, 'addresseeUser'>({
        name: 'addresseeUser',
        control,
        rules: { required: !disabled },
        defaultValue: null,
    });

    return (
        <UserAutocomplete
            required
            disabled={disabled}
            value={user}
            onChange={onUserChange}
            error={Boolean(errors.addresseeUser)}
            onBlur={onBlur}
            inputRef={ref}
        />
    );
};

export default AddresseeUserAutocomplete;
