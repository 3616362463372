import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import DropDownMenu from '@realcity/web-frame/lib/components/DropDownMenu';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import { useDeleteAlertMutation } from '../../graphql/graphql-typings';

interface Props {
    alertId: string;
}

const AlertEditingMenu: React.FC<Props> = ({ alertId }) => {
    const [executeDeleteAppAlert, mutationResult] = useDeleteAlertMutation();

    const deleteAppAlert = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeDeleteAppAlert({
            variables: {
                alertId,
            },
            update: (cache, { data }) => {
                const id = cache.identify(data!.deleteAlert);
                cache.evict({ id });
            },
            async onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    return (
        <>
            <MutationProgressDialog mutationResult={mutationResult} />

            <DropDownMenu buttonContent={<MoreHoriz />} horizontalAlignment="right">
                <MenuItem component={Link} to={`/maintenance/edit/${alertId}`}>
                    <FormattedMessage id="maintenance.edit" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={deleteAppAlert}>
                    <FormattedMessage id="maintenance.delete" />
                </MenuItem>
            </DropDownMenu>
        </>
    );
};

export default AlertEditingMenu;
