import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Role from './Role';
import ApiKeys from './scenes/ApiKeys';
import Maintenance from './scenes/Maintenance';
import Messages from './scenes/Messages';
import SendMessage from './scenes/SendMessage';
import UserDetails from './scenes/UserDetails';
import Users from './scenes/Users';
import CreateAlertForm from './scenes/CreateAlertForm';
import UpdateAlertForm from './scenes/UpdateAlertForm';
import APP_CONFIG from './config';
import Pois from './scenes/Pois';
import CreatePoiForm from './scenes/CreatePoiForm';
import UpdatePoiForm from './scenes/UpdatePoiForm';
import { PoisContextProvider } from './scenes/Pois/PoisContext';
import SeparatedBackofficeRedirect from './components/SeparatedBackofficeRedirect';

const RedirectToRoot = () => (<Navigate to={APP_CONFIG.DEFAULT_PAGE ?? '/users'} />);

const AppRoutes: React.FunctionComponent = () => (
    <Routes>
        <Route
            path="/users"
            element={(
                <SeparatedBackofficeRedirect host={APP_CONFIG.SEPARATED_BACKOFFICE_HOST_USERS}>
                    <Outlet />
                </SeparatedBackofficeRedirect>
            )}
        >
            <Route path="" element={<Users />} />
            <Route
                path=":userId"
                element={(
                    <IfRoleOrUnauthorizedFeatureMessage role={Role.USERS_READ}>
                        <UserDetails />
                    </IfRoleOrUnauthorizedFeatureMessage>
                )}
            />
        </Route>
        <Route
            path="/api-keys"
            element={(
                <SeparatedBackofficeRedirect host={APP_CONFIG.SEPARATED_BACKOFFICE_HOST_API_KEYS}>
                    <ApiKeys />
                </SeparatedBackofficeRedirect>
            )}
        />
        <Route
            path="/maintenance"
            element={(
                <SeparatedBackofficeRedirect host={APP_CONFIG.SEPARATED_BACKOFFICE_HOST_MAINTENANCE}>
                    <Outlet />
                </SeparatedBackofficeRedirect>
            )}
        >
            <Route
                path="new"
                element={(
                    <IfRoleOrUnauthorizedFeatureMessage role={Role.MAINTENANCE_WRITE}>
                        <CreateAlertForm />
                    </IfRoleOrUnauthorizedFeatureMessage>
                )}
            />
            <Route
                path="edit/:alertId"
                element={(
                    <IfRoleOrUnauthorizedFeatureMessage role={Role.MAINTENANCE_WRITE}>
                        <UpdateAlertForm />
                    </IfRoleOrUnauthorizedFeatureMessage>
                )}
            />
            <Route path="" element={<Maintenance />} />
        </Route>
        <Route
            path="/messages"
            element={(
                <SeparatedBackofficeRedirect host={APP_CONFIG.SEPARATED_BACKOFFICE_HOST_MESSAGES}>
                    <Outlet />
                </SeparatedBackofficeRedirect>
            )}
        >
            <Route path="new" element={<SendMessage />} />
            <Route path="" element={<Messages />} />
        </Route>
        <Route
            path="/poi"
            element={(
                <SeparatedBackofficeRedirect host={APP_CONFIG.SEPARATED_BACKOFFICE_HOST_POIS}>
                    <PoisContextProvider>
                        <Outlet />
                    </PoisContextProvider>
                </SeparatedBackofficeRedirect>
            )}
        >
            <Route path="new" element={<CreatePoiForm />} />
            <Route path="edit/:poiId" element={(<UpdatePoiForm />)} />
            <Route path="" element={<Pois />} />
        </Route>
        <Route path="*" element={<RedirectToRoot />} />
    </Routes>
);

export default AppRoutes;
