import type { ReactNode } from 'react';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { FormattedMessage } from 'react-intl';
import type { OpeningInterval } from '../../../generated/poi-service-internal';
import OpeningIntervalEditor from './OpeningIntervalEditor';

const EMPTY_INTERVAL: OpeningInterval = {
    from: 0,
    to: 0,
};

interface Props {
    intervals: OpeningInterval[];
    label: ReactNode;
    canDeleteSingleRow?: boolean;
    onChange: (intervals: OpeningInterval[]) => void;
    onCopy: (intervals: OpeningInterval[]) => void;
    disabled?: boolean;
}

const OpeningIntervalsEditor = ({ label, intervals, onChange, onCopy, canDeleteSingleRow, disabled }: Props) => {
    const handleCopy = () => {
        onCopy(intervals);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <FormControlLabel
                label={label}
                sx={{ width: 200 }}
                control={(
                    <Checkbox
                        disabled={disabled}
                        checked={intervals.length !== 0}
                        onChange={(e) => {
                            if (e.target.checked) {
                                onChange([EMPTY_INTERVAL]);
                            } else {
                                onChange([]);
                            }
                        }}
                    />
                )}
            />
            {intervals.length === 0 ? (
                <Box alignSelf="center">
                    <FormattedMessage id="poi-editor.poi.opening-hours.closed" />
                </Box>
            ) : (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {intervals.map((interval, intervalIndex) => {
                            const disableDelete = intervals.length === 1 && !canDeleteSingleRow;

                            return (
                                <OpeningIntervalEditor
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={intervalIndex}
                                    interval={interval}
                                    onChange={(changedInterval) => {
                                        onChange(intervals.map((originalInterval, i) => {
                                            if (i === intervalIndex) {
                                                return changedInterval;
                                            }
                                            return originalInterval;
                                        }));
                                    }}
                                    onDelete={disableDelete ? undefined : () => {
                                        onChange(intervals.filter((originalInterval, i) => i !== intervalIndex));
                                    }}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </Box>
                    <IconButton
                        onClick={() => {
                            onChange([...intervals, EMPTY_INTERVAL]);
                        }}
                        disabled={disabled}
                    >
                        <AddOutlined />
                    </IconButton>
                    <IconButton onClick={handleCopy} disabled={disabled}>
                        <ContentCopyOutlined />
                    </IconButton>
                </>
            )}
        </Box>
    );
};

export default OpeningIntervalsEditor;
