import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import type { QueryResultRendererProps } from './QueryResultRenderer';
import QueryResultRenderer from './QueryResultRenderer';

interface Props<K, TData, TVariables> extends QueryResultRendererProps<TData, TVariables> {
    property: K;
}

const ListQueryResultRenderer = <K extends string, TData extends { [P in K]: unknown[] }, TVariables>({
    queryResult,
    component: Component,
    property,
}: Props<K, TData, TVariables>) => {
    const component = useCallback(data => data[property].length === 0 ? (
        <p>
            <FormattedMessage id="list.no-items" />
        </p>
    ) : (
        <Component {...data} />
    ), [Component, property]);

    return (
        <QueryResultRenderer
            queryResult={queryResult}
            component={component}
        />
    );
};

export default ListQueryResultRenderer;
