import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { FormattedDisplayName, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import HtmlInput from '../../components/HtmlInput';
import type { PoiFormType } from './PoiFormValue';

interface Props {
    language: 'en' | 'hu';
    disabled?: boolean;
}

const PoiDetailsInput: React.FC<Props> = ({ language, disabled }) => {
    const { register, control, formState: { errors } } = useFormContext<PoiFormType>();
    const descriptionFieldName = `translations.${language}.description` as const;
    const { field: descriptionField } = useController<PoiFormType, typeof descriptionFieldName>({
        name: descriptionFieldName,
        control,
    });

    return (
        <>
            <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
                <FormattedDisplayName value={language} type="language" />
            </Typography>

            <TextField
                required
                fullWidth
                margin="normal"
                disabled={disabled}
                label={<FormattedMessage id="poi-editor.poi.name" />}
                error={Boolean(errors.translations?.[language]?.name)}
                {...register(`translations.${language}.name`, { required: true })}
            />

            <FormLabel>
                <FormattedMessage id="poi-editor.poi.description" />
            </FormLabel>
            <HtmlInput disableAlign disableImage {...descriptionField} readOnly={disabled} />
        </>
    );
};

export default PoiDetailsInput;
