import { useEffect, useRef, useState } from 'react';

export const MIN_SEARCH_LENGTH = 3;

interface UseThrottledSearchResult {
    search: string;
    setSearch: (search: string) => void;
    skip: boolean;
    throttledSearch: string;
    throttling: boolean;
}

function useThrottle<T>(value: T, delay: number): [value: T, throttling: boolean] {
    const now = Date.now();
    const lastTime = useRef(now);
    const lastValue = useRef(value);
    const timeout = useRef<number | null>(null);
    const [, forceRender] = useState({});

    function clearTimeout() {
        if (timeout.current !== null) {
            window.clearTimeout(timeout.current);
            timeout.current = null;
        }
    }

    useEffect(() => clearTimeout, []);

    if (value !== lastValue.current) {
        const remaining = lastTime.current + delay - now;
        if (remaining <= 0) {
            lastTime.current = now;
            lastValue.current = value;
        } else {
            clearTimeout();
            timeout.current = window.setTimeout(() => {
                timeout.current = null;
                forceRender({});
            }, remaining);
        }
    }
    return [lastValue.current, timeout.current !== null];
}

export function useThrottledSearch(): UseThrottledSearchResult {
    const [search, setSearch] = useState('');
    const skip = search.length < MIN_SEARCH_LENGTH;

    const [throttledSearch, throttling] = useThrottle(skip ? '' : search, 500);

    return {
        search,
        setSearch,
        skip,
        throttledSearch,
        throttling,
    };
}
