import React from 'react';
import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Role from '../../Role';
import PoiTable from './PoiTable';
import HeadingWithActions from '../../components/HeadingWithActions';
import ImportPoisButton from './ImportPoisButton';
import ExportPoisButton from './ExportPoisButton';

const Pois: React.FC = () => (
    <>
        <HeadingWithActions>
            <Typography variant="h1">
                <FormattedMessage id="poi-editor.title" />
            </Typography>
            <IfRole role={Role.POI_READ}>
                <ExportPoisButton />
            </IfRole>
            <IfRole role={Role.POI_WRITE}>
                <ImportPoisButton />
                <Button variant="outlined" component={Link} to="/poi/new">
                    <FormattedMessage id="poi-editor.create" />
                </Button>
            </IfRole>
        </HeadingWithActions>
        <IfRoleOrUnauthorizedFeatureMessage role={Role.POI_READ}>
            <PoiTable />
        </IfRoleOrUnauthorizedFeatureMessage>
    </>
);

export default Pois;
