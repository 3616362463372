import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
    value: boolean;
}

const FormattedCondition: React.FC<Props> = ({ value }) => {
    if (value) {
        return <FormattedMessage id="condition.yes" />;
    }
    return <FormattedMessage id="condition.no" />;
};

export default FormattedCondition;
