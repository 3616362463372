import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import type { ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedDateTime from '../../components/FormattedDateTime';
import TableDataView from '../../components/TableDataView';
import { TableDescriptor } from '../../components/TableView/TableDescriptor';
import UserLink from '../../components/UserLink';
import type { ListKeysQuery, UserApiKeyState } from '../../graphql/graphql-typings';
import { useListKeysQuery } from '../../graphql/graphql-typings';
import { useThrottledSearch } from '../../hooks/useThrottledSearch';
import Role from '../../Role';
import ApiKeyChangeStateButton from './ApiKeyChangeStateButton';

const DESCRIPTOR = new TableDescriptor<ListKeysQuery['keys']['items'][0]>()
    .customColumn({
        header: <FormattedMessage id="api-key.user-name" />,
        getValue: user => user,
        renderCell: ({ user }) => <UserLink user={user} />,
    })
    .column(<FormattedMessage id="api-key.company-name" />, 'companyName')
    .column(<FormattedMessage id="api-key.comment" />, 'comment', comment => (
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{comment}</Typography>
    ))
    .column(<FormattedMessage id="api-key.created-time" />, 'createdTime', createdTime => (
        <FormattedDateTime value={createdTime} />
    ))
    .column(<FormattedMessage id="api-key.state" />, 'state', stateMessage)
    .column(<FormattedMessage id="api-key.key" />, 'id', key => (
        <Typography sx={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}>{key}</Typography>
    ))
    .customColumn({
        header: null,
        getValue: user => user,
        renderCell: ({ id, state }) => (
            <IfRole role={Role.API_KEYS_WRITE}>
                <ApiKeyChangeStateButton apiKey={id} state={state} />
            </IfRole>
        ),
    });

function stateMessage(state: UserApiKeyState): ReactNode {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (state) {
        case 'ACTIVATED':
            return (
                <FormattedMessage id="api-key.state.activated" />
            );
        case 'PENDING':
            return (
                <FormattedMessage id="api-key.state.pending" />
            );
        case 'BLOCKED':
            return (
                <FormattedMessage id="api-key.state.blocked" />
            );
        case 'DELETED':
            return (
                <FormattedMessage id="api-key.state.deleted" />
            );
    }
}

const ApiKeys: React.FC = () => {
    const { search, setSearch, throttledSearch } = useThrottledSearch();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    return (
        <>
            <Typography variant="h1">
                <FormattedMessage id="api-keys.title" />
            </Typography>
            <IfRoleOrUnauthorizedFeatureMessage role={Role.API_KEYS_READ}>
                <TextField
                    value={search}
                    onChange={handleSearchChange}
                    sx={{ width: 500 }}
                    label={<FormattedMessage id="api-keys.search" />}
                    fullWidth
                />
                <TableDataView
                    descriptor={DESCRIPTOR}
                    query={useListKeysQuery}
                    queryOptions={{ variables: { search: throttledSearch || null } }}
                    dataName="keys"
                />
            </IfRoleOrUnauthorizedFeatureMessage>
        </>
    );
};

export default ApiKeys;
