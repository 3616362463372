import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import PoiForm from '../PoiForm';
import type { AsyncOptionsWithParam } from '../../hooks/useAsyncWithParams';
import useAsyncWithParams from '../../hooks/useAsyncWithParams';
import type { GetPoiRequest, Poi, UpdatePoiRequest } from '../../generated/poi-service-internal';
import { ApiClientContext } from '../../providers/ApiClientWrapper';
import { poiFormToUpdatableData, poiToPoiForm } from '../PoiForm/poi-form-utils';
import useDeferredAsync from '../../hooks/useDeferredAsync';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import UpdatePoiFormExtraActions from './UpdatePoiFormExtraActions';

const UpdatePoiForm: React.FC = () => {
    const poiId = useParams<'poiId'>().poiId!;
    const apiClient = useContext(ApiClientContext);

    // eslint-disable-next-line arrow-body-style
    const getPoi = useCallback(async (props: AsyncOptionsWithParam<GetPoiRequest, Poi>, controller: AbortController): Promise<Poi> => {
        return apiClient.getPoi(props.param, { signal: controller.signal });
    }, [apiClient]);

    const param = useMemo((): GetPoiRequest => ({ id: poiId }), [poiId]);

    const getPoiAsync = useAsyncWithParams({
        promiseFn: getPoi,
        param,
    });

    const { data } = getPoiAsync;

    // eslint-disable-next-line arrow-body-style
    const updatePoi = useCallback(async (variables: UpdatePoiRequest, controller: AbortController): Promise<{}> => {
        await apiClient.updatePoi(variables, controller);
        return {}; // return non-undefined value
    }, [apiClient]);

    const [updateResult, executeUpdate] = useDeferredAsync(updatePoi);

    if (!data) {
        return <CircularProgress />;
    }

    const onPoiSaved = () => {
        updateResult.reset();
        getPoiAsync.reload();
    };

    return (
        <>
            <PoiForm
                title={<FormattedMessage id="poi-form.editing-title" />}
                defaultValue={poiToPoiForm(data)}
                onSubmit={(formValue) => {
                    executeUpdate({
                        id: poiId,
                        poiUpdatableData: poiFormToUpdatableData(formValue),
                    });
                }}
                createdTime={data.createdTime}
                modifiedTime={data.modifiedTime}
                extraActions={<UpdatePoiFormExtraActions poiId={poiId} />}
            />
            <MutationProgressDialog
                mutationResult={updateResult}
                finishedDialogContent={(
                    <>
                        <DialogContent>
                            <FormattedMessage id="poi-form.poi-modified" />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onPoiSaved}>
                                <FormattedMessage id="api-command-dialog.ok" />
                            </Button>
                        </DialogActions>
                    </>
                )}
            />
        </>
    );
};

export default UpdatePoiForm;
