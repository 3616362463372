import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DetailView from '../../components/DetailView';
import FormattedDateTime from '../../components/FormattedDateTime';
import { TableDescriptor } from '../../components/TableView/TableDescriptor';
import UserStatus from '../../components/UserStatus';
import type { UserStatusInfoFragment } from '../../graphql/graphql-typings';

const DESCRIPTOR = new TableDescriptor<UserStatusInfoFragment>()
    .column(<FormattedMessage id="users.registered-at" />, 'registeredAt', registeredAt => <FormattedDateTime value={registeredAt} />)
    .column(<FormattedMessage id="users.status" />, 'status', status => <UserStatus status={status} />)
    .customColumn({
        header: <FormattedMessage id="users.deleted-at" />,
        getValue: user => user.deletedAt,
        renderCell: deletedAt => deletedAt && <FormattedDateTime value={deletedAt} />,
        renderIfNull: false,
    })
    .customColumn({
        header: <FormattedMessage id="users.reason-for-deletion" />,
        getValue: user => user,
        renderCell: ({ deletedAt, deletionReason }) => deletedAt && deletionReason,
        renderIfNull: false,
    });

export interface Props {
    user: UserStatusInfoFragment;
}

const UserStatusTable: React.FC<Props> = ({ user }) => (
    <>
        <Typography variant="h2">
            <FormattedMessage id="users.status" />
        </Typography>

        <DetailView descriptor={DESCRIPTOR} item={user} />
    </>
);

export default UserStatusTable;
