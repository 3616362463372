import React, { useCallback, useMemo, useState } from 'react';
import CustomTablePagination from './CustomTablePagination';
import TableView from './TableView';
import type { Identified } from './TableView';
import type { Column, TableDescriptor } from './TableView/TableDescriptor';
import type ColumnFilter from './TableView/ColumnFilter';

export interface Props<T> {
    descriptor: TableDescriptor<T>;
    items: T[];
    loading?: boolean;
}

const PaginatedTableView = <T extends Identified>({ descriptor, items, loading }: Props<T>) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;

    const [columnFilters, setColumnFilters] = useState(() => createColumnFilters(descriptor.getColumns()));

    const getFilteredItems = useCallback((filters?: ColumnFilter<T, unknown>[]) => {
        if (!filters) {
            return items;
        }
        return items.filter((item) => {
            for (const filter of filters) {
                const columnValue = filter.column.getValue(item);
                if (filter.selected.length !== 0 && !filter.selected.includes(columnValue)) {
                    return false;
                }
            }
            return true;
        });
    }, [items]);

    const filteredItems = useMemo(() => getFilteredItems(columnFilters), [getFilteredItems, columnFilters]);

    // eslint-disable-next-line @typescript-eslint/comma-dangle
    const onColumnFilterChange = useCallback(<R, >(column: Column<T, R>, value: R[]) => {
        setColumnFilters(currentColumnFilters => currentColumnFilters.map((cf) => {
            if (cf.column !== column) {
                return cf;
            }
            return {
                ...cf,
                selected: value,
            };
        }));
        setPage(0);
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
    };

    return (
        <>
            <TableView
                descriptor={descriptor}
                items={filteredItems.slice(startIndex, startIndex + rowsPerPage)}
                loading={loading}
                filterProvider={{
                    getFilteredItems,
                    columnFilters,
                    onColumnFilterChange,
                }}
            />

            <CustomTablePagination
                count={filteredItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

function createColumnFilters<T>(columns: readonly Column<T, unknown>[]): ColumnFilter<T, unknown>[] {
    // todo rename
    return columns
        .filter(column => column.enableFilter)
        // eslint-disable-next-line @typescript-eslint/comma-dangle
        .map(<R, >(column: Column<T, R>): ColumnFilter<T, R> => ({
            column,
            selected: [],
        }));
}

export default PaginatedTableView;
