import type { IntlShape } from 'react-intl/src/types';
import type { Poi, PoiCreatableData, PoiUpdatableData, OpeningHours } from '../../generated/poi-service-internal';
import type { PoiFormValue } from './PoiFormValue';
import type { PoiType } from '../../config';
import { DayOfWeek } from '../../generated/poi-service-internal';
import OpeningHoursType from './OpeningHoursType';

export function poiFormToUpdatableData(formValue: PoiFormValue): PoiUpdatableData {
    const { type, status, coordinates: { latitude, longitude }, operational, translations, openingHours } = formValue;
    return {
        type,
        status,
        name: { hu: translations.hu.name, en: translations.en.name },
        description: { hu: translations.hu.description, en: translations.en.description },
        latitude,
        longitude,
        operational,
        openingHours,
    };
}

export function poiFormToCreatableData(formValue: PoiFormValue): PoiCreatableData {
    return {
        id: formValue.id || undefined,
        ...poiFormToUpdatableData(formValue),
    };
}

export function poiToPoiForm({ id, type, status, latitude, longitude, operational, name, description, openingHours }: Poi): PoiFormValue {
    return {
        id,
        type,
        status,
        coordinates: {
            latitude,
            longitude,
        },
        operational,
        translations: {
            hu: {
                name: name.hu,
                description: description.hu,
            },
            en: {
                name: name.en,
                description: description.en,
            },
        },
        openingHours,
    };
}

export function translatePoiType(intl: IntlShape, poiType: PoiType): string {
    if (typeof poiType.name === 'string') {
        return poiType.name;
    }
    const localizedName = poiType.name[intl.locale];
    if (localizedName !== undefined) {
        return localizedName;
    }
    return poiType.value;
}

export function getOpeningHoursType(openingHours: OpeningHours | undefined): OpeningHoursType {
    if (!openingHours) {
        return OpeningHoursType.UNSET;
    }
    if (openingHours.exceptions.length === 0 && openingHours.periods.length === 1 && openingHours.periods[0].day === DayOfWeek.O247) {
        return OpeningHoursType.O247;
    }
    return OpeningHoursType.CUSTOM;
}
