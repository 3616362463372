import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedDisplayName, FormattedMessage } from 'react-intl';
import type { SendMessageFormValue } from './SendMessageForm';

export interface MessageTranslation {
    title: string;
    text: string;
}

interface Props {
    language: 'en' | 'hu';
}

const MessageTranslationForm: React.FC<Props> = ({ language }) => {
    const { register, formState: { errors } } = useFormContext<SendMessageFormValue>();

    return (
        <>
            <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
                <FormattedDisplayName value={language} type="language" />
            </Typography>
            <TextField
                required
                margin="normal"
                fullWidth
                label={<FormattedMessage id="message.title" />}
                error={Boolean(errors[language]?.title)}
                {...register(`${language}.title`, { required: true })}
            />
            <TextField
                required
                margin="normal"
                fullWidth
                multiline
                rows={5}
                label={<FormattedMessage id="message.text" />}
                error={Boolean(errors[language]?.text)}
                {...register(`${language}.text`, { required: true })}
            />
        </>
    );
};

export default MessageTranslationForm;
