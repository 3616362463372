import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
    open: boolean;
    onClose: () => void;
}

const MessageDialog: React.FC<Props> = ({ open, onClose, children }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                <FormattedMessage id="message-dialog.ok" />
            </Button>
        </DialogActions>
    </Dialog>
);

export default MessageDialog;
