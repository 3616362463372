import React, { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import type { WFState } from '@realcity/web-frame';
import type { RequestContext, HTTPHeaders } from '../generated/poi-service-internal';
import { DefaultApi, Configuration } from '../generated/poi-service-internal';

export const ApiClientContext = createContext<DefaultApi>(null as unknown as DefaultApi);

const ApiClientWrapper: React.FC = ({ children }) => {
    const keycloak = useSelector((state: WFState) => state.auth.keycloak!);
    const [apiClient] = useState(() => new DefaultApi(new Configuration({
        basePath: '/api/poi-service',
        middleware: [{
            pre: async (context: RequestContext): Promise<void> => {
                await keycloak.updateToken(30);
                const token = keycloak.token!;
                (context.init.headers as HTTPHeaders).Authorization = `Bearer ${token}`;
            },
        }],
    })));

    return (
        <ApiClientContext.Provider value={apiClient}>
            {children}
        </ApiClientContext.Provider>
    );
};

export default ApiClientWrapper;
