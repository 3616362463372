import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useContext } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ApiClientContext } from '../../providers/ApiClientWrapper';
import useDeferredAsync from '../../hooks/useDeferredAsync';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import type { ImportError, ImportStatistics } from '../../generated/poi-service-internal';
import { PoisContext } from './PoisContext';
import ImportPoisErrors from './ImportPoisErrors';
import { POI_EDITOR_ID_REQUIRED, POI_TYPES } from '../../config';

const ALLOWED_TYPES = POI_TYPES.map(({ value }) => value);

const ImportPoisButton: React.FC = () => {
    const apiClient = useContext(ApiClientContext);
    const { reloadTable } = useContext(PoisContext);

    const importPois = useCallback(async (file: File, controller: AbortController): Promise<ImportStatistics> => {
        try {
            return await apiClient.importPois({
                idRequired: POI_EDITOR_ID_REQUIRED,
                allowedTypes: ALLOWED_TYPES,
                body: file as unknown as string,
            }, controller);
        } catch (e) {
            if (!(e instanceof Response)) {
                throw e;
            }
            throw await e.json();
        }
    }, [apiClient]);

    const [result, executeImportPois] = useDeferredAsync(importPois);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (!files || files.length === 0) {
            return;
        }
        executeImportPois(files[0]);
        e.target.value = '';
    }, [executeImportPois]);

    const customErrorMessage = useCallback((error: unknown) => {
        if (!Array.isArray(error)) {
            return null;
        }
        return (
            <ImportPoisErrors errors={error as ImportError[]} />
        );
    }, []);

    return (
        <>
            <Button variant="text" component="label">
                <FormattedMessage id="poi-editor.import" />
                <input hidden accept="text/csv" type="file" onChange={onChange} />
            </Button>
            <MutationProgressDialog
                mutationResult={result}
                finishedDialogContent={(
                    <>
                        <DialogTitle>
                            <FormattedMessage id="poi-editor.import.successful-title" />
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <FormattedMessage id="poi-editor.import.successful-created" values={{ rows: result.data?.created }} />
                            </div>
                            <div>
                                <FormattedMessage id="poi-editor.import.successful-updated" values={{ rows: result.data?.updated }} />
                            </div>
                            <div>
                                <FormattedMessage id="poi-editor.import.successful-deleted" values={{ rows: result.data?.deleted }} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    result.reset();
                                    reloadTable();
                                }}
                            >
                                <FormattedMessage id="message-dialog.ok" />
                            </Button>
                        </DialogActions>
                    </>
                )}
                customErrorMessage={customErrorMessage}
            />
        </>
    );
};

export default ImportPoisButton;
