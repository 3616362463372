/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * A nyitvatartási idő napja. Az egyes napok mellett, lehet HOL (ünnepnap) és O247 (éjjel-nappal nyitva).
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    MON = 'MON',
    TUE = 'TUE',
    WED = 'WED',
    THU = 'THU',
    FRI = 'FRI',
    SAT = 'SAT',
    SUN = 'SUN',
    HOL = 'HOL',
    O247 = 'O247'
}

export function DayOfWeekFromJSON(json: any): DayOfWeek {
    return DayOfWeekFromJSONTyped(json, false);
}

export function DayOfWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayOfWeek {
    return json as DayOfWeek;
}

export function DayOfWeekToJSON(value?: DayOfWeek | null): any {
    return value as any;
}

