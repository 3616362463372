import React from 'react';
import ListQueryResultRenderer from '../../../../components/ListQueryResultRenderer';
import { useListExtendedFavoritesQuery } from '../../../../graphql/graphql-typings';
import ExtendedUserFavoritesTable from './ExtendedUserFavoritesTable';

interface Props {
    userId: string;
}

const ExtendedUserFavorites: React.FC<Props> = ({ userId }) => {
    const queryResult = useListExtendedFavoritesQuery({
        fetchPolicy: 'network-only',
        variables: { userId },
    });

    return (
        <ListQueryResultRenderer queryResult={queryResult} component={ExtendedUserFavoritesTable} property="extendedFavorites" />
    );
};

export default ExtendedUserFavorites;
