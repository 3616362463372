/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DayOfWeek,
    DayOfWeekFromJSON,
    DayOfWeekFromJSONTyped,
    DayOfWeekToJSON,
    OpeningInterval,
    OpeningIntervalFromJSON,
    OpeningIntervalFromJSONTyped,
    OpeningIntervalToJSON,
    OpeningPeriodAllOf,
    OpeningPeriodAllOfFromJSON,
    OpeningPeriodAllOfFromJSONTyped,
    OpeningPeriodAllOfToJSON,
} from './';




/**
 * 
 * @export
 * @interface OpeningPeriod
 */

export interface OpeningPeriod {
    /**
     * Kezdő időpont másodpercben éjféltől számítva
     * @type {number}
     * @memberof OpeningPeriod
     */
    from: number;
    /**
     * Vég időpont másodpercben éjféltől számítva
     * @type {number}
     * @memberof OpeningPeriod
     */
    to: number;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof OpeningPeriod
     */
    day: DayOfWeek;
}

export function OpeningPeriodFromJSON(json: any): OpeningPeriod {
    return OpeningPeriodFromJSONTyped(json, false);
}

export function OpeningPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'from': json['from'],
        'to': json['to'],
        'day': DayOfWeekFromJSON(json['day']),
    } as any;
}

export function OpeningPeriodToJSON(value?: OpeningPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'from': value.from,
        'to': value.to,
        'day': DayOfWeekToJSON(value.day),
    };
}

