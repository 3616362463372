import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import FavoriteTypeRenderer from '../../../components/FavoriteType';
import type { UserFavoritesFragment } from '../../../graphql/graphql-typings';
import FavoriteNameRenderer from './FavoriteNameRenderer';

interface Props {
    favorites: UserFavoritesFragment[];
}

const UserFavoritesTable: React.FC<Props> = ({ favorites }) => (
    <Table>
        <TableBody>
            {favorites.map(favorite => (
                <TableRow key={favorite.id}>
                    <TableCell align="right" width="300px"><FavoriteTypeRenderer type={favorite.type} /></TableCell>
                    <TableCell>
                        <FavoriteNameRenderer favorite={favorite} />
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default UserFavoritesTable;
