import React from 'react';
import { FormattedMessage } from 'react-intl';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import { useAddCommentMutation } from '../../graphql/graphql-typings';
import ConfirmWithCommentButton from './ConfirmWithCommentButton';

interface Props {
    userId: string;
}

const AddCommentButton: React.FC<Props> = ({ userId }) => {
    const [executeSetState, mutationResult] = useAddCommentMutation();

    const addComment = (comment: string) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeSetState({
            variables: {
                id: userId,
                comment,
            },
        });
    };

    return (
        <>
            <ConfirmWithCommentButton
                variant="outlined"
                confirmMessage={<FormattedMessage id="users.comments.add" />}
                onConfirm={addComment}
            >
                <FormattedMessage id="users.comments.add" />
            </ConfirmWithCommentButton>
            <MutationProgressDialog mutationResult={mutationResult} />
        </>
    );
};

export default AddCommentButton;
