import type { QueryHookOptions, QueryResult } from '@apollo/client';
import React, { useState } from 'react';
import type { Pagination, PaginationInfo } from '../../graphql/graphql-typings';
import useStateWithReset from '../../hooks/useStateWithReset';
import CustomTablePagination from '../CustomTablePagination';
import TableView from '../TableView';
import type { TableDescriptor } from '../TableView/TableDescriptor';

const DEFAULT_POSITION: Position = {
    page: 0,
    cursor: null,
};

interface PaginatedResponse<T> {
    paginationInfo: PaginationInfo;
    items: T[];
}

interface PaginatedVariables {
    pagination: Pagination;
}

interface Position {
    page: number;
    cursor: string | null;
}

interface Props<T, U, K, V extends PaginatedVariables> {
    descriptor: TableDescriptor<T>;
    query: (baseOptions: QueryHookOptions<U, V>) => QueryResult<U, V>;
    dataName: K;
    // eslint-disable-next-line react/require-default-props
    queryOptions?: QueryHookOptions<U, Omit<V, 'pagination'>>;
}

const TableDataView = <T extends { id: string }, K extends string, U extends { [P in K]: PaginatedResponse<T> },
    V extends PaginatedVariables>({ descriptor, query, dataName, queryOptions }: Props<T, U, K, V>) => {
    const [position, setPosition] = useStateWithReset<Position, string>(
        DEFAULT_POSITION,
        JSON.stringify(queryOptions?.variables ?? null),
    );
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const queryResult = query({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        ...queryOptions as QueryHookOptions<U, V> | undefined,
        variables: { ...queryOptions?.variables, pagination: { cursor: position.cursor, rows: rowsPerPage } } as V,
    });
    const { data, previousData, loading, error } = queryResult;

    const lastData = data || previousData;
    const lastResult = lastData ? lastData[dataName] : null;

    const onPageChange = (newPage: number) => {
        if (newPage === 0 || !lastResult) {
            setPosition(DEFAULT_POSITION);
        } else {
            const { paginationInfo } = lastResult;
            const cursor = position.page > newPage ? paginationInfo.previousCursor : paginationInfo.nextCursor;
            setPosition({
                page: newPage,
                cursor,
            });
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        onPageChange(0);
    };

    return (
        <>
            <TableView
                descriptor={descriptor}
                items={lastResult?.items ?? []}
                loading={loading}
                error={error}
            />

            <CustomTablePagination
                count={lastResult && !loading ? lastResult.paginationInfo.totalCount ?? -1 : 0}
                rowsPerPage={rowsPerPage}
                page={loading ? 0 : position.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default TableDataView;
