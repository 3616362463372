import { useState } from 'react';

export interface UseOpenResult {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export function useOpen(initialOpen = false): UseOpenResult {
    const [open, setOpen] = useState(initialOpen);

    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return {
        open,
        onOpen,
        onClose,
    };
}
