/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpeningHours,
    OpeningHoursFromJSON,
    OpeningHoursFromJSONTyped,
    OpeningHoursToJSON,
    PoiStatus,
    PoiStatusFromJSON,
    PoiStatusFromJSONTyped,
    PoiStatusToJSON,
} from './';




/**
 * 
 * @export
 * @interface PoiUpdatableData
 */

export interface PoiUpdatableData {
    /**
     * 
     * @type {PoiStatus}
     * @memberof PoiUpdatableData
     */
    status: PoiStatus;
    /**
     * 
     * @type {string}
     * @memberof PoiUpdatableData
     */
    type: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoiUpdatableData
     */
    name: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoiUpdatableData
     */
    description: { [key: string]: string; };
    /**
     * Üzemel
     * @type {boolean}
     * @memberof PoiUpdatableData
     */
    operational: boolean;
    /**
     * 
     * @type {OpeningHours}
     * @memberof PoiUpdatableData
     */
    openingHours?: OpeningHours;
    /**
     * 
     * @type {number}
     * @memberof PoiUpdatableData
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof PoiUpdatableData
     */
    longitude: number;
}

export function PoiUpdatableDataFromJSON(json: any): PoiUpdatableData {
    return PoiUpdatableDataFromJSONTyped(json, false);
}

export function PoiUpdatableDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiUpdatableData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'status': PoiStatusFromJSON(json['status']),
        'type': json['type'],
        'name': json['name'],
        'description': json['description'],
        'operational': json['operational'],
        'openingHours': !exists(json, 'openingHours') ? undefined : OpeningHoursFromJSON(json['openingHours']),
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    } as any;
}

export function PoiUpdatableDataToJSON(value?: PoiUpdatableData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'status': PoiStatusToJSON(value.status),
        'type': value.type,
        'name': value.name,
        'description': value.description,
        'operational': value.operational,
        'openingHours': OpeningHoursToJSON(value.openingHours),
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

