import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DetailView from '../../../components/DetailView';
import FormattedCondition from '../../../components/FormattedCondition';
import FormattedDateTime from '../../../components/FormattedDateTime';
import { TableDescriptor } from '../../../components/TableView/TableDescriptor';
import type {
    UserPurchaseCardDetailsFragment,
    UserPurchaseDetailsFragment,
    UserPurchaseInvoiceDetailsFragment,
} from '../../../graphql/graphql-typings';
import PurchaseStatusText from './PurchaseStatusText';

const DESCRIPTOR = new TableDescriptor<UserPurchaseDetailsFragment>()
    .column(<FormattedMessage id="purchase.head-id" />, 'purchaseHeadId')
    .column(<FormattedMessage id="purchase.transaction-id" />, 'transactionId')
    .column(<FormattedMessage id="purchase.order-ref" />, 'orderRef')
    .column(<FormattedMessage id="purchase.nmfr-id" />, 'purchaseNMFRId')
    .column(<FormattedMessage id="purchase.product-nmfr-number" />, 'productUniquePrefix')
    .column(<FormattedMessage id="purchase.product-name" />, 'productNameHU')
    .column(<FormattedMessage id="purchase.product-owner-name" />, 'productOwnerName')
    .column(<FormattedMessage id="purchase.cost-info" />, 'costInfo')
    .column(<FormattedMessage id="purchase.validity-start" />, 'validityStart', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.validity-end" />, 'validityEnd', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.usability-end" />, 'usabilityEnd', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.purchase-date" />, 'purchaseDate', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.auto-purchase-enabled" />, 'autoPurchaseEnabled', value => value !== null && (
        <FormattedCondition value={value} />
    ))
    .column(<FormattedMessage id="purchase.auto-purchase-enabled-time" />, 'autoPurchaseEnabledTime', value => value && (
        <FormattedDateTime value={value} />
    ))
    .column(<FormattedMessage id="purchase.storno" />, 'storno', value => value !== null && <FormattedCondition value={value} />)
    .column(<FormattedMessage id="purchase.storno-date" />, 'stornoDate', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.storno-invoice-number" />, 'child', child => child?.invoiceData?.invoiceNumber)
    .column(<FormattedMessage id="purchase.parent-invoice-number" />, 'parent', parent => parent?.invoiceData?.invoiceNumber)
    .column(<FormattedMessage id="purchase.status" />, 'status', value => value && <PurchaseStatusText value={value} />);

const INVOICE_DESCRIPTOR = new TableDescriptor<UserPurchaseInvoiceDetailsFragment>()
    .column(<FormattedMessage id="purchase.invoice.invoice-number" />, 'invoiceNumber')
    .column(<FormattedMessage id="purchase.invoice.address-name" />, 'addressName')
    .column(<FormattedMessage id="purchase.invoice.email" />, 'email')
    .column(<FormattedMessage id="purchase.invoice.country" />, 'country', value => value?.isoCode)
    .column(<FormattedMessage id="purchase.invoice.postal-code" />, 'postalCode')
    .column(<FormattedMessage id="purchase.invoice.city-name" />, 'cityName')
    .column(<FormattedMessage id="purchase.invoice.address" />, 'address')
    .column(<FormattedMessage id="purchase.invoice.tax-number" />, 'taxNumber')
    .column(<FormattedMessage id="purchase.invoice.group-tax-number" />, 'groupTaxNumber');

const CARD_DESCRIPTOR = new TableDescriptor<UserPurchaseCardDetailsFragment>()
    .column(<FormattedMessage id="purchase.card.id" />, 'id')
    .column(<FormattedMessage id="purchase.card.validity" />, 'validity', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.card.number" />, 'cardNumber')
    .column(<FormattedMessage id="purchase.card.active" />, 'active', value => value !== null && <FormattedCondition value={value} />)
    .column(<FormattedMessage id="purchase.card.created" />, 'created', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.card.modified" />, 'modified', value => value && <FormattedDateTime value={value} />);

interface Props {
    purchase: UserPurchaseDetailsFragment;
}

const PurchaseDetails: React.FC<Props> = ({ purchase }) => (
    <>
        <DetailView descriptor={DESCRIPTOR} item={purchase} />
        {purchase.invoiceData && (
            <>
                <Typography variant="h2">
                    <FormattedMessage id="purchase.invoice-details" />
                </Typography>
                <DetailView descriptor={INVOICE_DESCRIPTOR} item={purchase.invoiceData} />
            </>
        )}
        {purchase.card && (
            <>
                <Typography variant="h2">
                    <FormattedMessage id="purchase.card-details" />
                </Typography>
                <DetailView descriptor={CARD_DESCRIPTOR} item={purchase.card} />
            </>
        )}
    </>
);

export default PurchaseDetails;
