/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PoiSortBy {
    ID = 'id',
    STATUS = 'status',
    NAME = 'name',
    OPERATIONAL = 'operational',
    CREATED_TIME = 'createdTime',
    MODIFIED_TIME = 'modifiedTime'
}

export function PoiSortByFromJSON(json: any): PoiSortBy {
    return PoiSortByFromJSONTyped(json, false);
}

export function PoiSortByFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiSortBy {
    return json as PoiSortBy;
}

export function PoiSortByToJSON(value?: PoiSortBy | null): any {
    return value as any;
}

