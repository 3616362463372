import React from 'react';
import { useIntl } from 'react-intl';
import type { TranslatedStringFragment } from '../../graphql/graphql-typings';

interface Props {
    translatedStrings: TranslatedStringFragment[];
}

const TranslatedText: React.FC<Props> = ({ translatedStrings }) => {
    const { locale } = useIntl();

    if (translatedStrings.length === 0) {
        return null;
    }

    const localizedDescription = translatedStrings.find(e => e.locale === locale) || translatedStrings[0];

    return (
        <>
            {localizedDescription.text}
        </>
    );
};

export default TranslatedText;
