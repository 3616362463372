import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import type { TableDescriptor } from '../TableView/TableDescriptor';

export interface Props<T> {
    descriptor: TableDescriptor<T>;
    item: T;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DetailView = <T,>({ descriptor, item }: Props<T>) => (
    <Table>
        <TableBody>
            {descriptor
                .getColumns()
                .map(column => ({
                    header: column.header,
                    cell: column.renderCell(column.getValue(item)),
                    renderIfNull: column.renderIfNull ?? true,
                }))
                .filter(column => column.cell !== null || column.renderIfNull)
                .map((column, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={i}>
                        <TableCell align="right" width="300px">
                            {column.header}
                        </TableCell>
                        <TableCell>{column.cell}</TableCell>
                    </TableRow>
                ))}
        </TableBody>
    </Table>
);

export default DetailView;
