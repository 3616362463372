import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import Typography from '@mui/material/Typography';

interface Props {
    value: Date | number | string;
}

const FormattedDateTime: React.FC<Props> = ({ value }) => (
    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
        <FormattedDate value={value} />
        {' '}
        <FormattedTime value={value} />
    </Typography>
);

export default FormattedDateTime;
