/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PoiStatus,
    PoiStatusFromJSON,
    PoiStatusFromJSONTyped,
    PoiStatusToJSON,
} from './';




/**
 * 
 * @export
 * @interface PoiBatchUpdate
 */

export interface PoiBatchUpdate {
    /**
     * A frissíteni kívánt POI-k azonosítói
     * @type {Array<string>}
     * @memberof PoiBatchUpdate
     */
    ids: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PoiBatchUpdate
     */
    operational?: boolean;
    /**
     * 
     * @type {PoiStatus}
     * @memberof PoiBatchUpdate
     */
    status?: PoiStatus;
}

export function PoiBatchUpdateFromJSON(json: any): PoiBatchUpdate {
    return PoiBatchUpdateFromJSONTyped(json, false);
}

export function PoiBatchUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiBatchUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'ids': json['ids'],
        'operational': !exists(json, 'operational') ? undefined : json['operational'],
        'status': !exists(json, 'status') ? undefined : PoiStatusFromJSON(json['status']),
    } as any;
}

export function PoiBatchUpdateToJSON(value?: PoiBatchUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'ids': value.ids,
        'operational': value.operational,
        'status': PoiStatusToJSON(value.status),
    };
}

