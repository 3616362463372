import React from 'react';
import { FormattedMessage } from 'react-intl';
import OpeningHoursType from '../OpeningHoursType';

interface Props {
    type: OpeningHoursType;
}

const OpeningHoursTypeText: React.FC<Props> = ({ type }) => {
    switch (type) {
        case OpeningHoursType.UNSET:
            return <FormattedMessage id="poi-editor.poi.opening-hours.unset" />;
        case OpeningHoursType.O247:
            return <FormattedMessage id="poi-editor.poi.opening-hours.0-24" />;
        case OpeningHoursType.CUSTOM:
            return <FormattedMessage id="poi-editor.poi.opening-hours.custom" />;
    }
};

export default OpeningHoursTypeText;
