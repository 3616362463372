import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import type { AppVersionAndApiKeyFragment } from '../../graphql/graphql-typings';
import { affectedVersionsToString } from '../../utils';
import { API_KEY_PICKER_ENABLED } from '../../config';
import { groupApiKeys } from '../../alert-utils';

interface DisplayedApiKey {
    title: string;
    versions: string[];
}

interface Props {
    affectedVersions: AppVersionAndApiKeyFragment[];
}

const AffectedVersions: React.FC<Props> = ({ affectedVersions }) => {
    if (affectedVersions.length === 0) {
        return (<FormattedMessage id="maintenance.all-versions" />);
    }

    const concatAppAndVersion = affectedVersionsToString(affectedVersions);

    if (API_KEY_PICKER_ENABLED) {
        const groupedApiKeys = groupApiKeys(affectedVersions);
        const displayedApiKeys = [
            ...groupedApiKeys.predefined.filter(a => a.enabled).map(({ name, versions }) => ({ title: name, versions })),
            ...groupedApiKeys.custom.filter(a => a.enabled).map(({ apiKey, versions }) => ({ title: apiKey, versions })),
        ];

        return (
            <Tooltip title={concatAppAndVersion}>
                <span>
                    {displayedApiKeys.map(formatDisplayedApiKey).join(', ')}
                </span>
            </Tooltip>
        );
    }

    return (
        <>
            {concatAppAndVersion}
        </>
    );
};

function formatDisplayedApiKey(apiKey: DisplayedApiKey): string {
    if (apiKey.versions.length === 0) {
        return apiKey.title;
    }
    return `${apiKey.title} (${apiKey.versions.join(', ')})`;
}

export default AffectedVersions;
