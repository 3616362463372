import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import type { TimeInterval } from '../../../components/TimeIntervalInput';
import TimeIntervalInput from '../../../components/TimeIntervalInput';
import type { OpeningInterval } from '../../../generated/poi-service-internal';

interface Props {
    interval: OpeningInterval;
    onChange: (interval: OpeningInterval) => void;
    onDelete?: () => void;
    disabled?: boolean;
}

const OpeningIntervalEditor: React.FC<Props> = ({ interval, onChange, onDelete, disabled }) => {
    const value = useMemo((): TimeInterval => ({
        fromSeconds: interval.from,
        toSeconds: interval.to,
    }), [interval]);

    return (
        <Box sx={{ display: 'flex' }}>
            <TimeIntervalInput
                value={value}
                onChange={({ fromSeconds, toSeconds }) => {
                    onChange({
                        from: fromSeconds,
                        to: toSeconds,
                    });
                }}
                disabled={disabled}
            />
            <IconButton
                sx={{ visibility: !onDelete ? 'hidden' : undefined }} // fills space when not displayed
                onClick={onDelete}
                disabled={disabled}
            >
                <DeleteOutlined />
            </IconButton>
        </Box>
    );
};

export default OpeningIntervalEditor;
