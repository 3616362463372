/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpeningException,
    OpeningExceptionFromJSON,
    OpeningExceptionFromJSONTyped,
    OpeningExceptionToJSON,
    OpeningPeriod,
    OpeningPeriodFromJSON,
    OpeningPeriodFromJSONTyped,
    OpeningPeriodToJSON,
} from './';




/**
 * 
 * @export
 * @interface OpeningHours
 */

export interface OpeningHours {
    /**
     * 
     * @type {Array<OpeningPeriod>}
     * @memberof OpeningHours
     */
    periods: Array<OpeningPeriod>;
    /**
     * 
     * @type {Array<OpeningException>}
     * @memberof OpeningHours
     */
    exceptions: Array<OpeningException>;
}

export function OpeningHoursFromJSON(json: any): OpeningHours {
    return OpeningHoursFromJSONTyped(json, false);
}

export function OpeningHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'periods': ((json['periods'] as Array<any>).map(OpeningPeriodFromJSON)),
        'exceptions': ((json['exceptions'] as Array<any>).map(OpeningExceptionFromJSON)),
    } as any;
}

export function OpeningHoursToJSON(value?: OpeningHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'periods': ((value.periods as Array<any>).map(OpeningPeriodToJSON)),
        'exceptions': ((value.exceptions as Array<any>).map(OpeningExceptionToJSON)),
    };
}

