import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { DialogProps } from '@mui/material/Dialog';
import type { ReactNode } from 'react';
import React from 'react';
import useConfirm from '../hooks/useConfirm';

interface Props extends Omit<ButtonProps, 'onClick'> {
    dialogTitle: ReactNode;
    dialogContent: ReactNode;
    onConfirm: () => void;
    confirmDisabled?: boolean;
    dialogProps?: Omit<DialogProps, 'onClose' | 'open'>;
}

const ConfirmButton: React.FC<Props> = ({ dialogTitle, dialogContent, onConfirm, confirmDisabled, dialogProps, ...rest }) => {
    const [confirmDialog, showConfirm] = useConfirm({
        onConfirm,
        dialogProps,
        dialogContent,
        dialogTitle,
        confirmDisabled,
    });

    return (
        <>
            <Button {...rest} onClick={showConfirm} />
            {confirmDialog}
        </>
    );
};

export default ConfirmButton;
