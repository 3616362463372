import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface Props {
    link: string;
}

const MutationSuccessMessage: React.FC<Props> = ({ link, children }) => (
    <>
        <DialogContent>
            {children}
        </DialogContent>
        <DialogActions>
            <Button component={Link} to={link}>
                <FormattedMessage id="api-command-dialog.ok" />
            </Button>
        </DialogActions>
    </>
);

export default MutationSuccessMessage;
