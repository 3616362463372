import TablePagination from '@mui/material/TablePagination';
import type { TablePaginationProps } from '@mui/material/TablePagination';
import React from 'react';

const ROWS_PER_PAGE = [10, 25, 100];

const CustomTablePagination: React.FC<TablePaginationProps> = props => (
    <TablePagination sx={{ overflow: 'unset' }} rowsPerPageOptions={ROWS_PER_PAGE} component="div" {...props} />
);

export default CustomTablePagination;
