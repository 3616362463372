import React from 'react';
import Chip from '@mui/material/Chip';
import { FormattedMessage } from 'react-intl';
import { PoiStatus } from '../../generated/poi-service-internal';

interface Props {
    status: PoiStatus;
}

const PoiStatusChip: React.FC<Props> = ({ status }) => (
    <Chip
        label={getPoiStatusText(status)}
        sx={{ height: 21, color: '#fff', backgroundColor: getStatusColor(status) }}
    />
);

function getPoiStatusText(status: PoiStatus) {
    switch (status) {
        case PoiStatus.DRAFT:
            return <FormattedMessage id="poi-editor.poi.status.draft" />;
        case PoiStatus.PUBLISHED:
            return <FormattedMessage id="poi-editor.poi.status.published" />;
        default:
            return null;
    }
}

function getStatusColor(status: PoiStatus): string {
    return status === PoiStatus.PUBLISHED ? '#219653' : '#828282';
}

export default PoiStatusChip;
