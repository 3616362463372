/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
    Poi,
    PoiFromJSON,
    PoiFromJSONTyped,
    PoiToJSON,
    PoisAllOf,
    PoisAllOfFromJSON,
    PoisAllOfFromJSONTyped,
    PoisAllOfToJSON,
} from './';




/**
 * 
 * @export
 * @interface Pois
 */

export interface Pois {
    /**
     * 
     * @type {number}
     * @memberof Pois
     */
    total: number;
    /**
     * POI-k listája
     * @type {Array<Poi>}
     * @memberof Pois
     */
    list: Array<Poi>;
}

export function PoisFromJSON(json: any): Pois {
    return PoisFromJSONTyped(json, false);
}

export function PoisFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pois {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'total': json['total'],
        'list': ((json['list'] as Array<any>).map(PoiFromJSON)),
    } as any;
}

export function PoisToJSON(value?: Pois | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'total': value.total,
        'list': ((value.list as Array<any>).map(PoiToJSON)),
    };
}

