import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import React, { useCallback, useContext } from 'react';
import TextField from '@mui/material/TextField';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import type { ValidateResult } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { PoiFormType, PoiFormValue } from './PoiFormValue';
import HeadingWithActions from '../../components/HeadingWithActions';
import { POI_EDITOR_ID_REQUIRED, POI_TYPES } from '../../config';
import { translatePoiType } from './poi-form-utils';
import PoiOperationalChip from '../Pois/PoiOperationalChip';
import PoiStatusChip from '../Pois/PoiStatusChip';
import Role from '../../Role';
import LocationEditor from './LocationEditor';
import OpeningHoursEditor from './OpeningHoursEditor';
import { POI_OPERATIONAL_VALUES, POI_STATUSES } from './OpeningHoursEditor/constants';
import PoiDetailsInput from './PoiDetailsInput';
import { ApiClientContext } from '../../providers/ApiClientWrapper';
import useHasRole from '../../hooks/useHasRole';

interface Props {
    title: ReactNode;
    defaultValue: Omit<PoiFormValue, 'coordinates'>;
    onSubmit: (poi: PoiFormValue) => void;
    extraActions?: ReactNode;
    createdTime?: Date;
    modifiedTime?: Date;
}

const PoiForm: React.FC<Props> = ({ title, defaultValue, onSubmit, extraActions, createdTime, modifiedTime }) => {
    const intl = useIntl();
    const hasRole = useHasRole();
    const apiClient = useContext(ApiClientContext);

    const form = useForm<PoiFormType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: defaultValue,
    });

    const updatingExisting = Boolean(defaultValue.id);

    const idAvailable = useCallback(async (id: string): Promise<boolean> => {
        try {
            await apiClient.getPoi({ id });
            return false;
        } catch (e) {
            return e instanceof Response && e.status === 404;
        }
    }, [apiClient]);

    const validateIdField = useCallback(async (id: string): Promise<ValidateResult> => {
        if (updatingExisting) {
            return true;
        }
        return idAvailable(id);
    }, [idAvailable, updatingExisting]);

    const { control, register, handleSubmit, formState } = form;
    const disableEdit = !hasRole(Role.POI_WRITE);

    return (
        <FormProvider {...form}>
            <HeadingWithActions>
                <Typography variant="h1">
                    {title}
                </Typography>
                {extraActions}
                <Button variant="outlined" component={Link} to="/poi">
                    <FormattedMessage id="poi-form.cancel" />
                </Button>
                <IfRole role={Role.POI_WRITE}>
                    <Button
                        variant="contained"
                        onClick={handleSubmit((data) => {
                            onSubmit(data);
                        })}
                    >
                        <FormattedMessage id="poi-form.save" />
                    </Button>
                </IfRole>
            </HeadingWithActions>
            <Typography variant="h2">
                <FormattedMessage id="poi-form.section.basic" />
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Controller
                    control={control}
                    name="type"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                            error={Boolean(fieldState.error)}
                            disabled={disableEdit}
                            required
                        >
                            <InputLabel>
                                <FormattedMessage id="poi-editor.poi.type" />
                            </InputLabel>
                            <Select {...field}>
                                {POI_TYPES.map(type => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {translatePoiType(intl, type)}
                                    </MenuItem>
                                ))}
                                {defaultValue.type !== '' && !POI_TYPES.find(type => type.value === defaultValue.type) && (
                                    <MenuItem value={defaultValue.type}>
                                        {defaultValue.type}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    )}
                />
                <TextField
                    variant="standard"
                    label={<FormattedMessage id="poi-editor.poi.id" />}
                    disabled={updatingExisting}
                    required={POI_EDITOR_ID_REQUIRED}
                    error={Boolean(formState.errors.id)}
                    sx={{ minWidth: 320 }}
                    {...register('id', { required: POI_EDITOR_ID_REQUIRED, validate: validateIdField })}
                />
                <FormControl variant="standard" sx={{ minWidth: 120 }} required disabled={disableEdit}>
                    <InputLabel>
                        <FormattedMessage id="poi-editor.poi.operational" />
                    </InputLabel>
                    <Controller
                        control={control}
                        name="operational"
                        render={({ field }) => (
                            <Select {...field}>
                                {POI_OPERATIONAL_VALUES.map(operational => (
                                    <MenuItem key={operational.toString()} value={operational as any}>
                                        <PoiOperationalChip operational={operational} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
                <FormControl variant="standard" sx={{ minWidth: 120 }} required disabled={disableEdit}>
                    <InputLabel>
                        <FormattedMessage id="poi-editor.poi.status" />
                    </InputLabel>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field }) => (
                            <Select {...field}>
                                {POI_STATUSES.map(status => (
                                    <MenuItem key={status} value={status}>
                                        <PoiStatusChip status={status} />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
                <Stack spacing={2} direction="row">
                    {createdTime && (
                        <TextField
                            variant="standard"
                            label={<FormattedMessage id="poi-editor.poi.created-time" />}
                            disabled
                            value={formatDateTime(intl, createdTime)}
                        />
                    )}
                    {modifiedTime && (
                        <TextField
                            variant="standard"
                            label={<FormattedMessage id="poi-editor.poi.modified-time" />}
                            disabled
                            value={formatDateTime(intl, modifiedTime)}
                        />
                    )}
                </Stack>
            </Box>

            <Typography variant="h2">
                <FormattedMessage id="poi-form.section.description" />
            </Typography>
            <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} lg={6}>
                    <PoiDetailsInput language="hu" disabled={disableEdit} />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <PoiDetailsInput language="en" disabled={disableEdit} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg="auto" minWidth={540}>
                    <Typography variant="h2">
                        <FormattedMessage id="poi-form.section.opening-hours" />
                    </Typography>
                    <Controller
                        control={control}
                        name="openingHours"
                        render={({ field }) => <OpeningHoursEditor field={field} disabled={disableEdit} />}
                    />
                </Grid>
                <Grid item flex={1}>
                    <Typography variant="h2">
                        <FormattedMessage id="poi-form.section.coordinates" />
                    </Typography>
                    <Controller
                        control={control}
                        name="coordinates"
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <LocationEditor
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={Boolean(error)}
                                disabled={disableEdit}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </FormProvider>
    );
};

function formatDateTime(intl: IntlShape, createdTime: Date) {
    return `${intl.formatDate(createdTime)} ${intl.formatTime(createdTime)}`;
}

export default PoiForm;
