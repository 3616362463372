import IfMissingRole from '@realcity/web-frame/lib/components/Auth/IfMissingRole';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import React from 'react';
import Role from '../../../Role';
import ExtendedUserFavorites from './ExtendedUserFavorites';
import SimpleUserFavorites from './SimpleUserFavorites';

interface Props {
    userId: string;
}

const UserFavorites: React.FC<Props> = ({ userId }) => (
    <>
        <IfRole role={Role.DEBUG}>
            <ExtendedUserFavorites userId={userId} />
        </IfRole>
        <IfMissingRole role={Role.DEBUG}>
            <SimpleUserFavorites userId={userId} />
        </IfMissingRole>
    </>
);

export default UserFavorites;
