import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FormattedDateTime from '../../components/FormattedDateTime';
import HeadingWithActions from '../../components/HeadingWithActions';
import TableDataView from '../../components/TableDataView';
import { TableDescriptor } from '../../components/TableView/TableDescriptor';
import TranslatedText from '../../components/TranslatedText';
import type { AppAlertSummaryFragment } from '../../graphql/graphql-typings';
import { useListAlertsQuery } from '../../graphql/graphql-typings';
import Role from '../../Role';
import AffectedVersions from './AffectedVersions';
import AlertEditingMenu from './AlertEditingMenu';

const DESCRIPTOR = new TableDescriptor<AppAlertSummaryFragment>()
    .column(<FormattedMessage id="maintenance.start-time" />, 'startTime', startTime => (
        <FormattedDateTime value={startTime} />
    ))
    .column(<FormattedMessage id="maintenance.end-time" />, 'endTime', endTime => endTime ? (
        <FormattedDateTime value={endTime} />) : null)
    .column(<FormattedMessage id="maintenance.summary" />, 'summary', summary => (
        <TranslatedText translatedStrings={summary} />
    ))
    .column(
        <Typography sx={{ minWidth: '180px' }}><FormattedMessage id="maintenance.affected-versions" /></Typography>,
        'appVersions',
        appVersions => <AffectedVersions affectedVersions={appVersions} />,
    )
    .column('', 'id', id => (
        <IfRole role={Role.MAINTENANCE_WRITE}>
            <AlertEditingMenu alertId={id} />
        </IfRole>
    ));

const Maintenance: React.FC = () => (
    <>
        <HeadingWithActions>
            <Typography variant="h1">
                <FormattedMessage id="maintenance.title" />
            </Typography>
            <IfRole role={Role.MAINTENANCE_WRITE}>
                <Button variant="outlined" component={Link} to="/maintenance/new">
                    <FormattedMessage id="maintenance.create" />
                </Button>
            </IfRole>
        </HeadingWithActions>
        <IfRoleOrUnauthorizedFeatureMessage role={Role.MAINTENANCE_READ}>
            <TableDataView
                descriptor={DESCRIPTOR}
                query={useListAlertsQuery}
                dataName="alerts"
            />
        </IfRoleOrUnauthorizedFeatureMessage>
    </>
);

export default Maintenance;
