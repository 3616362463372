import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import type { ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import MessageDialog from './MessageDialog';

export interface MutationResult<T = any, E = Error> {
    data?: T;
    error?: E;
    loading: boolean;
    reset: () => void;
}

interface Props<T, E> {
    mutationResult: MutationResult<T, E>;
    finishedDialogContent?: ReactNode | ((result: T) => ReactNode);
    customErrorMessage?: (error: E) => ReactNode;
}

const MutationProgressDialog = <T, E extends Error>({ mutationResult, finishedDialogContent, customErrorMessage }: Props<T, E>) => {
    // eslint-disable-next-line  @typescript-eslint/unbound-method
    const { loading, data, error, reset } = mutationResult;

    return (
        <>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            {finishedDialogContent && (
                <Dialog open={Boolean(data)}>
                    {data && typeof finishedDialogContent === 'function' && finishedDialogContent(data)}
                    {typeof finishedDialogContent !== 'function' && finishedDialogContent}
                </Dialog>
            )}
            {error && (
                <MessageDialog open onClose={reset}>
                    {customErrorMessage?.(error) || <FormattedMessage id="mutation.error" />}
                </MessageDialog>
            )}
        </>
    );
};

export default MutationProgressDialog;
