import type { ButtonProps } from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmButton from '../../components/ConfirmButton';

interface Props extends Omit<ButtonProps, 'onClick'> {
    confirmMessage: ReactNode;
    confirmDescription?: ReactNode;
    commentRequired?: boolean;
    onConfirm: (comment: string) => void;
}

const ConfirmWithCommentButton: React.FC<Props> = ({ confirmMessage, confirmDescription, commentRequired, onConfirm, ...rest }) => {
    const [comment, setComment] = useState('');

    return (
        <ConfirmButton
            dialogTitle={confirmMessage}
            confirmDisabled={commentRequired && comment === ''}
            dialogContent={(
                <>
                    {confirmDescription}
                    <TextField
                        multiline
                        minRows={3}
                        fullWidth
                        sx={{ mt: 1 }}
                        required={commentRequired}
                        label={<FormattedMessage id="users.comments.text" />}
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                    />
                </>
            )}
            onConfirm={() => {
                onConfirm(comment);
                setComment('');
            }}
            dialogProps={{ fullWidth: true }}
            {...rest}
        />
    );
};

export default ConfirmWithCommentButton;
