import React from 'react';
import { FormattedMessage } from 'react-intl';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import { useDeleteUserMutation } from '../../graphql/graphql-typings';
import ConfirmWithCommentButton from './ConfirmWithCommentButton';

interface Props {
    userId: string;
}

const UserDeleteButton: React.FC<Props> = ({ userId }) => {
    const [executeDeleteUser, mutationResult] = useDeleteUserMutation();

    const deleteUser = (comment: string) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeDeleteUser({
            variables: {
                id: userId,
                comment,
            },
        });
    };

    return (
        <>
            <MutationProgressDialog mutationResult={mutationResult} />
            <ConfirmWithCommentButton
                variant="contained"
                color="error"
                commentRequired
                confirmMessage={<FormattedMessage id="users.delete.confirm" />}
                confirmDescription={<FormattedMessage id="users.delete.confirm-description" />}
                onConfirm={deleteUser}
            >
                <FormattedMessage id="users.delete" />
            </ConfirmWithCommentButton>
        </>
    );
};

export default UserDeleteButton;
