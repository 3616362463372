import type { QueryResult } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import RequestErrorMessage from '@realcity/web-frame/lib/components/Message/RequestErrorMessage';
import type { ComponentType } from 'react';
import React from 'react';

export interface QueryResultRendererProps<TData, TVariables> {
    queryResult: QueryResult<TData, TVariables>;
    component: ComponentType<TData>;
}

const QueryResultRenderer = <TData, TVariables>({ queryResult, component: Component }: QueryResultRendererProps<TData, TVariables>) => {
    const { loading, data, error } = queryResult;

    if (loading) {
        return <CircularProgress />;
    }

    if (!data || error) {
        return <RequestErrorMessage />;
    }

    return <Component {...data} />;
};

export default QueryResultRenderer;
