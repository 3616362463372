import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import RequestErrorMessage from '@realcity/web-frame/lib/components/Message/RequestErrorMessage';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { UserAutocompleteOptionFragment } from '../../graphql/graphql-typings';
import { useSearchUsersQuery } from '../../graphql/graphql-typings';
import { useOpen } from '../../hooks/useOpen';
import { MIN_SEARCH_LENGTH, useThrottledSearch } from '../../hooks/useThrottledSearch';

interface Props {
    value: UserAutocompleteOptionFragment | null;
    onChange: (value: UserAutocompleteOptionFragment | null) => void;
    onBlur?: () => void;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    inputRef?: any;
}

const UserAutocomplete: React.FC<Props> = ({ value, onChange, onBlur, disabled, required, error, inputRef }) => {
    const { open, onOpen, onClose } = useOpen();
    const { search, setSearch, skip, throttledSearch, throttling } = useThrottledSearch();

    const { data, loading, error: queryError } = useSearchUsersQuery({
        fetchPolicy: 'network-only',
        variables: {
            rows: 10,
            search: throttledSearch,
        },
        skip: skip || !open,
    });

    const options = data && !skip ? data.listUsers.items : [];

    // value must be present in options array
    const optionsWithValue = value && options.length && !options.some(opt => optionEquals(opt, value)) ? [...options, value] : options;

    return (
        <Autocomplete
            sx={{ width: 500 }}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            options={optionsWithValue}
            loading={loading || throttling}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            autoComplete
            includeInputInList
            disabled={disabled}
            value={value}
            inputValue={search}
            isOptionEqualToValue={optionEquals}
            onChange={(event: unknown, newValue: UserAutocompleteOptionFragment | null) => {
                onChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setSearch(newInputValue);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    onBlur={onBlur}
                    inputRef={inputRef}
                    error={error}
                    required={required}
                    label={<FormattedMessage id="user-autocomplete.title" />}
                    fullWidth
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    <div>
                        {option.customerId}
                        {' '}
                        {option.displayName}
                        <Typography variant="subtitle2">
                            {option.email}
                        </Typography>
                    </div>
                </li>
            )}
            noOptionsText={getNoOptionsText(skip, Boolean(queryError))}
        />
    );
};

function getNoOptionsText(skip: boolean, queryError: boolean) {
    if (skip) {
        return <FormattedMessage id="search.too-short" values={{ characters: MIN_SEARCH_LENGTH }} />;
    }
    if (queryError) {
        return <RequestErrorMessage />;
    }
    return <FormattedMessage id="user-autocomplete.no-options" />;
}

function optionEquals(o1: UserAutocompleteOptionFragment, o2: UserAutocompleteOptionFragment): boolean {
    return o1.id === o2.id;
}

function getOptionLabel(option: UserAutocompleteOptionFragment) {
    return option.displayName;
}

function filterOptions<T>(options: T): T {
    return options;
}

export default UserAutocomplete;
