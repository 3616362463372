import Button from '@mui/material/Button';
import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import { UserApiKeyState, useSetStateMutation } from '../../graphql/graphql-typings';

interface Props {
    apiKey: string;
    state: UserApiKeyState;
}

const ApiKeyChangeStateButton: React.FC<Props> = ({ apiKey, state }) => {
    const [executeSetState, mutationResult] = useSetStateMutation();

    function changeState(newState: UserApiKeyState) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeSetState({
            variables: {
                key: apiKey,
                state: newState,
            },
        });
    }

    const activate = () => {
        changeState(UserApiKeyState.Activated);
    };

    const block = () => {
        changeState(UserApiKeyState.Blocked);
    };

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let content: ReactNode;
    switch (state) {
        case UserApiKeyState.Deleted:
            content = null;
            break;
        case UserApiKeyState.Activated:
            content = (
                <Button onClick={block}>
                    <FormattedMessage id="api-keys.block" />
                </Button>
            );
            break;
        default:
            content = (
                <Button onClick={activate}>
                    <FormattedMessage id="api-keys.activate" />
                </Button>
            );
    }
    return (
        <>
            <MutationProgressDialog mutationResult={mutationResult} />
            {content}
        </>
    );
};

export default ApiKeyChangeStateButton;
