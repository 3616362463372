import Typography from '@mui/material/Typography';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import React from 'react';
import { FormattedDisplayName, FormattedMessage } from 'react-intl';
import DetailView from '../../components/DetailView';
import IdentityProvider from '../../components/IdentityProvider';
import { TableDescriptor } from '../../components/TableView/TableDescriptor';
import type { UserBaseInfoFragment } from '../../graphql/graphql-typings';
import Role from '../../Role';
import AddCommentButton from './AddCommentButton';
import AdministratorCommentList from './AdministratorCommentList';

const DESCRIPTOR = new TableDescriptor<UserBaseInfoFragment>()
    .column(<FormattedMessage id="users.customer-id" />, 'customerId')
    .column(<FormattedMessage id="users.name" />, 'displayName')
    .column(<FormattedMessage id="users.email" />, 'email')
    .column(
        <FormattedMessage id="users.language" />,
        'locale',
        language => language && <FormattedDisplayName type="language" value={language} />,
    )
    .column(<FormattedMessage id="users.phone-number" />, 'phoneNumber')
    .column(<FormattedMessage id="users.marketing-consent" />, 'marketingConsent', marketingConsent => marketingConsent ? (
        <FormattedMessage id="users.marketing-consent.accepted" />
    ) : (
        <FormattedMessage id="users.marketing-consent.declined" />
    ))
    .column(<FormattedMessage id="users.identity-providers" />, 'identityProviders', identityProviders => (
        <>
            {identityProviders.map(idp => (
                <IdentityProvider key={idp.name} providerName={idp.name} userName={idp.userName} />
            ))}
        </>
    ))
    .column(<FormattedMessage id="users.password" />, 'hasPassword', hasPassword => hasPassword
        ? <FormattedMessage id="users.password.exists" /> : <FormattedMessage id="users.password.not-exists" />);

export interface Props {
    user: UserBaseInfoFragment;
}

const UserBaseInfoTable: React.FC<Props> = ({ user }) => (
    <>
        <Typography variant="h2">
            <FormattedMessage id="users.base-info" />
        </Typography>

        <DetailView descriptor={DESCRIPTOR} item={user} />
        <Typography variant="h2">
            <FormattedMessage id="users.comments" />
        </Typography>
        <AdministratorCommentList comments={user.administratorComments} />
        <IfRole role={Role.USERS_WRITE}>
            <AddCommentButton userId={user.id} />
        </IfRole>
    </>
);

export default UserBaseInfoTable;
