import type { AsyncState } from 'react-async';
import { useAsync } from 'react-async';
import { useCallback, useMemo, useRef } from 'react';

type DeferFnWithParams<V, T> = (variables: V, controller: AbortController) => Promise<T>;

interface AugmentedAsyncState<T> extends Omit<AsyncState<T>, 'isLoading' | 'run'> {
    reset: () => void;
    loading: boolean;
}

type UseDeferredAsyncResult<V, T> = [state: AugmentedAsyncState<T>, execute: (variables: V) => void];

export default function useDeferredAsync<V, T>(deferFn: DeferFnWithParams<V, T>): UseDeferredAsyncResult<V, T> {
    const handleRef = useRef({});

    const asyncState = useAsync<T>({
        deferFn: async (args, props, controller) => deferFn(args[0] as V, controller),
        watch: handleRef.current,
    });
    const { run } = asyncState;

    const execute = useCallback((variables: V) => {
        run(variables);
    }, [run]);

    return useMemo((): UseDeferredAsyncResult<V, T> => {
        const state: AugmentedAsyncState<T> = {
            ...asyncState,
            loading: asyncState.isLoading,
            reset: () => {
                handleRef.current = {};
                asyncState.setError(undefined as unknown as Error);
                asyncState.setData(undefined as unknown as T);
            },
        };
        return [state, execute];
    }, [asyncState, execute]);
}
