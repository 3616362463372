import Chip from '@mui/material/Chip';
import React from 'react';
import { UserPurchaseStatus } from '../../../graphql/graphql-typings';
import PurchaseStatusText from './PurchaseStatusText';

interface Props {
    value: UserPurchaseStatus;
}

const PurchaseStatusChip: React.FC<Props> = ({ value }) => {
    const [color, backgroundColor] = getStatusColor(value);
    return (
        <Chip label={<PurchaseStatusText value={value} />} sx={{ height: 21, color, backgroundColor }} />
    );
};

function getStatusColor(status: UserPurchaseStatus): [color: string, backgroundColor: string] {
    switch (status) {
        case UserPurchaseStatus.Ideiglenes:
            return ['#fff', '#2f80ed'];
        case UserPurchaseStatus.Kifizetett:
            return ['#fff', '#219653'];
        case UserPurchaseStatus.Torolt:
            return ['#31093d', '#f2f2f2'];
        case UserPurchaseStatus.Visszavaltas:
            return ['#fff', '#828282'];
        case UserPurchaseStatus.NmfrSztorno:
            return ['#fff', '#eb5757'];
        case UserPurchaseStatus.NmfrSztornoraVar:
            return ['#201414', '#ef8686'];
        case UserPurchaseStatus.Visszavalto:
            return ['#31093d', '#f7b478'];
    }
}

export default PurchaseStatusChip;
