import Close from '@mui/icons-material/Close';
import FeedOutlined from '@mui/icons-material/FeedOutlined';
import MobileFriendlyOutlined from '@mui/icons-material/MobileFriendlyOutlined';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { createContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BorderedTabs from '../../../components/BorderedTabs';
import FormattedDateTime from '../../../components/FormattedDateTime';
import HeadingWithActions from '../../../components/HeadingWithActions';
import IconTab from '../../../components/IconTab';
import PaginatedTableView from '../../../components/PaginatedTableView';
import { TableDescriptor } from '../../../components/TableView/TableDescriptor';
import type { UserPurchaseDetailsFragment } from '../../../graphql/graphql-typings';
import PurchaseDetails from './PurchaseDetails';
import PurchaseStatusChip from './PurchaseStatusChip';
import Validations from './Validations';

interface UserPurchasesContextValue {
    openDetails: (purchase: UserPurchaseDetailsFragment) => void;
    openValidations: (purchase: UserPurchaseDetailsFragment) => void;
}

const UserPurchasesContext = createContext<UserPurchasesContextValue>(null as unknown as UserPurchasesContextValue);

const DESCRIPTOR = new TableDescriptor<UserPurchaseDetailsFragment>()
    .customColumn({
        header: <FormattedMessage id="purchase.head-id" />,
        getValue: purchase => purchase.purchaseHeadId,
        renderCell: purchaseHeadId => purchaseHeadId,
        shouldCollapse: (p1, p2) => p1.purchaseHeadId === p2.purchaseHeadId, // todo refactor
        sx: { verticalAlign: 'top' },
    })
    .column(<FormattedMessage id="purchase.nmfr-id" />, 'purchaseNMFRId')
    .customColumn({
        header: <FormattedMessage id="purchase.product-name" />,
        getValue: purchase => purchase.productNameHU,
        renderCell: productName => productName,
        enableFilter: true,
    })
    .column(<FormattedMessage id="purchase.cost-info" />, 'costInfo')
    .column(<FormattedMessage id="purchase.purchase-date" />, 'purchaseDate', value => value && <FormattedDateTime value={value} />)
    .customColumn({
        header: <FormattedMessage id="purchase.status" />,
        getValue: purchase => purchase.status,
        renderCell: value => value && <PurchaseStatusChip value={value} />,
        enableFilter: true,
    })
    .column(<FormattedMessage id="purchase.usability-end" />, 'usabilityEnd', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.validity-start" />, 'validityStart', value => value && <FormattedDateTime value={value} />)
    .column(<FormattedMessage id="purchase.validity-end" />, 'validityEnd', value => value && <FormattedDateTime value={value} />)
    .customColumn({
        header: null,
        getValue: purchase => purchase,
        renderCell: purchase => (
            <UserPurchasesContext.Consumer>
                {({ openDetails, openValidations }) => (
                    <Box sx={{ display: 'flex' }}>
                        <Tooltip disableInteractive title={<FormattedMessage id="purchase.details" />}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    openDetails(purchase);
                                }}
                            >
                                <FeedOutlined />
                            </IconButton>
                        </Tooltip>

                        <Tooltip disableInteractive title={<FormattedMessage id="purchase.validations" />}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    openValidations(purchase);
                                }}
                            >
                                <MobileFriendlyOutlined />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </UserPurchasesContext.Consumer>
        ),
        sx: { padding: 0 },
    });

const enum DetailView {
    DETAILS = 'DETAILS',
    VALIDATIONS = 'VALIDATIONS'
}

interface Props {
    purchases: UserPurchaseDetailsFragment[];
}

const UserPurchasesTable: React.FC<Props> = ({ purchases }) => {
    const [selected, setSelected] = useState<UserPurchaseDetailsFragment | null>(null);
    const [detailView, setDetailView] = useState<DetailView>(DetailView.DETAILS);

    const openDetails = (purchase: UserPurchaseDetailsFragment) => {
        setSelected(purchase);
        setDetailView(DetailView.DETAILS);
    };

    const openValidations = (purchase: UserPurchaseDetailsFragment) => {
        setSelected(purchase);
        setDetailView(DetailView.VALIDATIONS);
    };

    const handleDetailViewChange = (e: unknown, newValue: DetailView) => {
        setDetailView(newValue);
    };

    const closeSelection = () => {
        setSelected(null);
    };

    const contextValue = useMemo(() => ({ openDetails, openValidations }), []);

    return (
        <UserPurchasesContext.Provider value={contextValue}>
            <PaginatedTableView descriptor={DESCRIPTOR} items={purchases} />
            <Drawer open={selected !== null} onClose={closeSelection} anchor="right" PaperProps={{ sx: { width: 600 } }}>
                {selected !== null && (
                    <HeadingWithActions>
                        <div>
                            <Typography variant="h1" sx={{ m: 1 }}>
                                {selected.productNameHU}
                            </Typography>
                            {selected.status && (
                                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                                    <PurchaseStatusChip value={selected.status} />
                                </Typography>
                            )}
                        </div>

                        <IconButton onClick={closeSelection}>
                            <Close />
                        </IconButton>
                    </HeadingWithActions>
                )}
                <BorderedTabs value={detailView} onChange={handleDetailViewChange}>
                    <IconTab
                        value={DetailView.DETAILS}
                        icon={<FeedOutlined />}
                        label={<FormattedMessage id="purchase.details" />}
                    />
                    <IconTab
                        value={DetailView.VALIDATIONS}
                        icon={<MobileFriendlyOutlined />}
                        label={<FormattedMessage id="purchase.validations" />}
                    />
                </BorderedTabs>
                <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
                    {selected !== null && detailView === DetailView.DETAILS && <PurchaseDetails purchase={selected} />}
                    {selected !== null && detailView === DetailView.VALIDATIONS && <Validations purchaseId={selected.id} />}
                </Box>
            </Drawer>
        </UserPurchasesContext.Provider>
    );
};

export default UserPurchasesTable;
