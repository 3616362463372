import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Role from '../../Role';
import AddresseeUserAutocomplete from './AddresseeUserAutocomplete';
import { AddresseeType } from './SendMessageForm';
import type { SendMessageFormValue } from './SendMessageForm';

const AddresseeSelector: React.FC = () => {
    const { control, resetField } = useFormContext<SendMessageFormValue>();
    const { field: { onChange, value } } = useController<SendMessageFormValue, 'addresseeType'>({
        name: 'addresseeType',
        control,
        defaultValue: AddresseeType.ALL,
    });

    const handleCategoryChange = (event: unknown, category: string) => {
        if (category === AddresseeType.ALL) {
            resetField('addresseeUser', { defaultValue: null });
        }
        onChange(category);
    };

    return (
        <>
            <Typography variant="h2" sx={{ marginTop: 0 }}>
                <FormattedMessage id="send-message.addressee" />
            </Typography>
            <RadioGroup value={value} onChange={handleCategoryChange}>
                <FormControlLabel
                    value={AddresseeType.ALL}
                    control={<Radio />}
                    label={<FormattedMessage id="send-message.addressee.active" />}
                />
                <IfRole role={Role.USERS_READ}>
                    <FormControlLabel
                        value={AddresseeType.SPECIFIC}
                        control={<Radio />}
                        label={<FormattedMessage id="send-message.addressee.specific" />}
                    />
                    <AddresseeUserAutocomplete disabled={value !== AddresseeType.SPECIFIC} />
                </IfRole>
            </RadioGroup>
        </>
    );
};

export default AddresseeSelector;
