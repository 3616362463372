import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FormattedDateTime from '../components/FormattedDateTime';
import HeadingWithActions from '../components/HeadingWithActions';
import TableDataView from '../components/TableDataView';
import { TableDescriptor } from '../components/TableView/TableDescriptor';
import TranslatedText from '../components/TranslatedText';
import UserLink from '../components/UserLink';
import type { ListMessagesQuery } from '../graphql/graphql-typings';
import { useListMessagesQuery } from '../graphql/graphql-typings';
import Role from '../Role';

const DESCRIPTOR = new TableDescriptor<ListMessagesQuery['messages']['items'][0]>()
    .column(<FormattedMessage id="message.sent" />, 'sent', sent => <FormattedDateTime value={sent} />)
    .customColumn({
        header: <FormattedMessage id="message.users" />,
        getValue: message => message,
        renderCell: (message) => {
            if (message.allDevices) {
                return <FormattedMessage id="message.all-devices" />;
            }
            return message.users.map(user => <UserLink key={user.id} user={user} />);
        },
    })
    .column(<FormattedMessage id="message.title" />, 'title', title => <TranslatedText translatedStrings={title} />)
    .column(<FormattedMessage id="message.text" />, 'text', text => <TranslatedText translatedStrings={text} />);

const Messages: React.FC = () => (
    <>
        <HeadingWithActions>
            <Typography variant="h1">
                <FormattedMessage id="messages.title" />
            </Typography>
            <IfRole role={Role.NOTIFICATIONS_WRITE}>
                <Button variant="outlined" component={Link} to="/messages/new">
                    <FormattedMessage id="send-message.title" />
                </Button>
            </IfRole>
        </HeadingWithActions>
        <IfRoleOrUnauthorizedFeatureMessage role={Role.NOTIFICATIONS_READ}>
            <TableDataView descriptor={DESCRIPTOR} query={useListMessagesQuery} dataName="messages" />
        </IfRoleOrUnauthorizedFeatureMessage>
    </>
);

export default Messages;
