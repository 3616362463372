import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import type { UserEnabledFragment } from '../../graphql/graphql-typings';
import { useSetUserEnabledMutation } from '../../graphql/graphql-typings';
import ConfirmWithCommentButton from './ConfirmWithCommentButton';

interface Props {
    user: UserEnabledFragment;
}

const UserChangeEnabledButton: React.FC<Props> = ({ user }) => {
    const [executeSetState, mutationResult] = useSetUserEnabledMutation();

    function changeState(newValue: boolean, comment: string) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeSetState({
            variables: {
                id: user.id,
                enabled: newValue,
                comment,
            },
        });
    }

    const activate = (comment: string) => {
        changeState(true, comment);
    };

    const block = (comment: string) => {
        changeState(false, comment);
    };

    const content: ReactNode = user.enabled ? (
        <ConfirmWithCommentButton
            variant="outlined"
            confirmMessage={<FormattedMessage id="users.status.disable.confirm" />}
            onConfirm={block}
            color="error"
        >
            <FormattedMessage id="users.status.disable" />
        </ConfirmWithCommentButton>
    ) : (
        <ConfirmWithCommentButton
            variant="outlined"
            confirmMessage={<FormattedMessage id="users.status.activate.confirm" />}
            onConfirm={activate}
        >
            <FormattedMessage id="users.status.activate" />
        </ConfirmWithCommentButton>
    );

    return (
        <>
            <MutationProgressDialog mutationResult={mutationResult} />
            {content}
        </>
    );
};

export default UserChangeEnabledButton;
