import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedDateTime from '../../../components/FormattedDateTime';
import TableDataView from '../../../components/TableDataView';
import { TableDescriptor } from '../../../components/TableView/TableDescriptor';
import TranslatedText from '../../../components/TranslatedText';
import type { ListUserMessagesQuery } from '../../../graphql/graphql-typings';
import { useListUserMessagesQuery } from '../../../graphql/graphql-typings';
import UserMessageStatusChip from './UserMessageStatusChip';

const DESCRIPTOR = new TableDescriptor<ListUserMessagesQuery['userMessages']['items'][0]>()
    .column(<FormattedMessage id="message.sent" />, 'message', ({ sent }) => <FormattedDateTime value={sent} />)
    .column(<FormattedMessage id="message.title" />, 'message', ({ title }) => <TranslatedText translatedStrings={title} />)
    .column(<FormattedMessage id="message.text" />, 'message', ({ text }) => <TranslatedText translatedStrings={text} />)
    .column(<FormattedMessage id="user-push-notification.status" />, 'status', status => <UserMessageStatusChip value={status} />);

interface Props {
    userId: string;
}

const UserMessages: React.FC<Props> = ({ userId }) => (
    <TableDataView
        descriptor={DESCRIPTOR}
        query={useListUserMessagesQuery}
        queryOptions={{ variables: { userId } }}
        dataName="userMessages"
    />
);

export default UserMessages;
