import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import type { ReactNode } from 'react';
import React from 'react';
import type { ApiKeyEntry, GroupedApiKeys } from '../../alert-utils';

interface Props {
    value: ApiKeyEntry;
    onUpdateValue: <K extends keyof GroupedApiKeys>(id: string, update: Partial<GroupedApiKeys[K][0]>) => void;
    apiKeyCell: ReactNode;
    extraAction?: ReactNode;
}

const ApiKeyEntryRow = ({ value: { id, apiKey, enabled, versions }, onUpdateValue, apiKeyCell, extraAction }: Props) => (
    <TableRow key={id}>
        <TableCell align="center">
            <Checkbox
                checked={enabled}
                name={apiKey}
                onChange={(e) => {
                    onUpdateValue(id, { enabled: e.target.checked });
                }}
            />
        </TableCell>
        <TableCell>
            {apiKeyCell}
        </TableCell>
        <TableCell>
            <TextField
                margin="dense"
                size="small"
                value={versions.join(',')}
                onChange={(e) => {
                    onUpdateValue(id, { versions: getVersions(e.target.value) });
                }}
            />
        </TableCell>
        <TableCell sx={{ p: 0 }}>
            {extraAction}
        </TableCell>
    </TableRow>
);

function getVersions(inputValue: string) {
    return inputValue === '' ? [] : inputValue.split(',').map(a => a.trim());
}

export default ApiKeyEntryRow;
