import React, { useState } from 'react';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import type { DateTime } from 'luxon';

interface Props {
    onSelect: (date: DateTime) => void;
}

const DatePickerDialogContent: React.FC<Props> = ({ onSelect }) => {
    const [date, setDate] = useState<DateTime | null>(null);

    const handleOk = () => {
        onSelect(date!);
    };

    return (
        <>
            <StaticDatePicker
                autoFocus
                views={['year', 'month', 'day']}
                displayStaticWrapperAs="desktop"
                value={date}
                onChange={setDate}
                renderInput={params => <TextField {...params} />}
            />
            <DialogActions>
                <Button disabled={date === null} onClick={handleOk}>
                    <FormattedMessage id="message-dialog.ok" />
                </Button>
            </DialogActions>
        </>
    );
};

export default DatePickerDialogContent;
