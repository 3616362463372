import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { ReactNode } from 'react';
import type { TableCellProps } from '@mui/material/TableCell';

export interface Column<T, R> {
    header: ReactNode;
    getValue: (row: T) => R;
    renderCell: (value: R) => ReactNode;
    renderIfNull?: boolean;
    enableFilter?: boolean;
    shouldCollapse?: (r1: T, r2: T) => boolean;
    align?: TableCellProps['align'];
    sx?: SxProps<Theme>;
}

export class TableDescriptor<T> {
    private readonly columns: Column<T, any>[] = [];

    column<K extends keyof T>(header: ReactNode, field: K, renderCell?: (value: T[K]) => ReactNode): this {
        return this.customColumn<T[K]>({
            header,
            getValue: row => row[field],
            renderCell: value => renderWith(value, renderCell),
        });
    }

    customColumn<R>(column: Column<T, R>): this {
        this.columns.push(column);
        return this;
    }

    getColumns(): readonly Column<T, unknown>[] {
        return this.columns;
    }
}

function renderWith<T>(value: T, renderer?: (value: T) => ReactNode) {
    return renderer ? renderer(value) : value;
}
