/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PoiStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    DELETED = 'DELETED'
}

export function PoiStatusFromJSON(json: any): PoiStatus {
    return PoiStatusFromJSONTyped(json, false);
}

export function PoiStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiStatus {
    return json as PoiStatus;
}

export function PoiStatusToJSON(value?: PoiStatus | null): any {
    return value as any;
}

