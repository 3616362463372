/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpeningHours,
    OpeningHoursFromJSON,
    OpeningHoursFromJSONTyped,
    OpeningHoursToJSON,
    PoiAllOf,
    PoiAllOfFromJSON,
    PoiAllOfFromJSONTyped,
    PoiAllOfToJSON,
    PoiCreatableData,
    PoiCreatableDataFromJSON,
    PoiCreatableDataFromJSONTyped,
    PoiCreatableDataToJSON,
    PoiStatus,
    PoiStatusFromJSON,
    PoiStatusFromJSONTyped,
    PoiStatusToJSON,
} from './';




/**
 * 
 * @export
 * @interface Poi
 */

export interface Poi {
    /**
     * 
     * @type {PoiStatus}
     * @memberof Poi
     */
    status: PoiStatus;
    /**
     * 
     * @type {string}
     * @memberof Poi
     */
    type: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Poi
     */
    name: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Poi
     */
    description: { [key: string]: string; };
    /**
     * Üzemel
     * @type {boolean}
     * @memberof Poi
     */
    operational: boolean;
    /**
     * 
     * @type {OpeningHours}
     * @memberof Poi
     */
    openingHours?: OpeningHours;
    /**
     * 
     * @type {number}
     * @memberof Poi
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Poi
     */
    longitude: number;
    /**
     * A POI egyedi azonosítója
     * @type {string}
     * @memberof Poi
     */
    id: string;
    /**
     * Létrehozás dátuma
     * @type {Date}
     * @memberof Poi
     */
    createdTime: Date;
    /**
     * Utolsó módosítás dátuma
     * @type {Date}
     * @memberof Poi
     */
    modifiedTime: Date;
}

export function PoiFromJSON(json: any): Poi {
    return PoiFromJSONTyped(json, false);
}

export function PoiFromJSONTyped(json: any, ignoreDiscriminator: boolean): Poi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'status': PoiStatusFromJSON(json['status']),
        'type': json['type'],
        'name': json['name'],
        'description': json['description'],
        'operational': json['operational'],
        'openingHours': !exists(json, 'openingHours') ? undefined : OpeningHoursFromJSON(json['openingHours']),
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'id': json['id'],
        'createdTime': (new Date(json['createdTime'])),
        'modifiedTime': (new Date(json['modifiedTime'])),
    } as any;
}

export function PoiToJSON(value?: Poi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'status': PoiStatusToJSON(value.status),
        'type': value.type,
        'name': value.name,
        'description': value.description,
        'operational': value.operational,
        'openingHours': OpeningHoursToJSON(value.openingHours),
        'latitude': value.latitude,
        'longitude': value.longitude,
        'id': value.id,
        'createdTime': (value.createdTime.toISOString()),
        'modifiedTime': (value.modifiedTime.toISOString()),
    };
}

