import Box from '@mui/material/Box';
import type { TabsProps } from '@mui/material/Tabs';
import Tabs from '@mui/material/Tabs';
import React from 'react';

const BorderedTabs: React.FC<TabsProps> = props => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs {...props} />
    </Box>
);

export default BorderedTabs;
