import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import MutationSuccessMessage from '../../components/MutationSuccessMessage';
import { ApiClientContext } from '../../providers/ApiClientWrapper';
import type { DeletePoiRequest } from '../../generated/poi-service-internal';
import useDeferredAsync from '../../hooks/useDeferredAsync';
import ConfirmButton from '../../components/ConfirmButton';
import Role from '../../Role';

interface Props {
    poiId: string;
}

const UpdatePoiFormExtraActions: React.FC<Props> = ({ poiId }) => {
    const apiClient = useContext(ApiClientContext);

    // eslint-disable-next-line arrow-body-style
    const deletePoi = useCallback(async (variables: DeletePoiRequest, controller: AbortController): Promise<{}> => {
        await apiClient.deletePoi(variables, controller);
        return {};
    }, [apiClient]);

    const [result, executeDeletePoi] = useDeferredAsync(deletePoi);

    return (
        <IfRole role={Role.POI_WRITE}>
            <ConfirmButton
                color="error"
                dialogTitle={<FormattedMessage id="poi-form.delete.confirm" />}
                dialogContent={null}
                onConfirm={() => {
                    executeDeletePoi({
                        id: poiId,
                    });
                }}
            >
                <FormattedMessage id="poi-form.delete" />
            </ConfirmButton>

            <MutationProgressDialog
                mutationResult={result}
                finishedDialogContent={(
                    <MutationSuccessMessage link="/poi">
                        <FormattedMessage id="poi-form.poi-deleted" />
                    </MutationSuccessMessage>
                )}
            />
        </IfRole>
    );
};

export default UpdatePoiFormExtraActions;
