import type { ApolloError } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RequestErrorMessage from '@realcity/web-frame/lib/components/Message/RequestErrorMessage';
import type { ReactNode } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import type { Column, TableDescriptor } from './TableDescriptor';
import type ColumnFilter from './ColumnFilter';
import ColumnFilterSelector from './ColumnFilterSelector';
import type FilterProvider from './FilterProvider';

export interface Identified {
    id: number | string;
}

export interface Props<T> {
    descriptor: TableDescriptor<T>;
    items: T[];
    filterProvider?: FilterProvider<T>;
    loading?: boolean;
    error?: ApolloError;
}

const TableView = <T extends Identified>({ descriptor, items, filterProvider, loading, error }: Props<T>) => {
    const columns = descriptor.getColumns();

    return (
        <TableContainer sx={{ position: 'relative' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableCell align={column.align} key={i}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        {column.header}
                                    </div>
                                    {mapOptional(
                                        filterProvider?.columnFilters.find(filter => filter.column === column),
                                        /* eslint-disable-next-line @typescript-eslint/comma-dangle */
                                        <R, >(filter: ColumnFilter<T, R>) => (
                                            <ColumnFilterSelector filter={filter} filterProvider={filterProvider!} />
                                        ),
                                    )}
                                </Box>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderRows(items, columns)}
                </TableBody>
            </Table>
            <Backdrop open={Boolean(loading || error)} sx={{ position: 'absolute' }}>
                {loading && <CircularProgress />}
                {error && <RequestErrorMessage />}
            </Backdrop>
        </TableContainer>
    );
};

function renderRows<T extends Identified, R>(items: T[], columns: readonly Column<T, R>[]) {
    const rowCollapses = columns.map(() => 0);

    const result: ReactNode[] = [];
    for (let i = items.length - 1; i >= 0; i--) {
        const item = items[i];
        const previousItem = i > 0 ? items[i - 1] : null;

        const cells: ReactNode[] = [];
        for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
            const column = columns[columnIndex];

            if (previousItem !== null && column.shouldCollapse && column.shouldCollapse(item, previousItem)) {
                rowCollapses[columnIndex]++;
            } else {
                const cell = (
                    <TableCell align={column.align} sx={column.sx} key={columnIndex} rowSpan={rowCollapses[columnIndex] + 1}>
                        {column.renderCell(column.getValue(item))}
                    </TableCell>
                );
                cells.push(cell);
                rowCollapses[columnIndex] = 0;
            }
        }

        const row = (
            <TableRow key={item.id}>
                {cells}
            </TableRow>
        );
        result.unshift(row);
    }

    return result;
}

function mapOptional<T, R>(value: T | undefined, mapper: (value: T) => R): R | undefined {
    if (value === undefined) {
        return;
    }
    return mapper(value);
}

export default TableView;
