import type { PropsWithChildren } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
    host: string | undefined;
}

/**
 * Redirects the browser to an external host with the same path, if the external host is set.
 * */
const SeparatedBackofficeRedirect = ({ host, children }: PropsWithChildren<Props>) => {
    const location = useLocation();

    if (host) {
        window.location.replace(host + location.pathname + location.search + location.hash);
        return null;
    }

    return (
        <>
            {children}
        </>
    );
};

export default SeparatedBackofficeRedirect;
