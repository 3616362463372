import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import HeadingWithActions from '../../components/HeadingWithActions';
import type { AppAlertFormType, AppAlertFormValue } from './AppAlertFormValue';
import PeriodInput from './PeriodInput';
import AlertMessageInput from './AlertMessageInput';
import AppVersionsInputPanel from './AppVersionsInputPanel';
import { LANG_EN, LANG_HU } from './alert-utils';

const useStyles = makeStyles({
    messageInput: {
        display: 'flex',
        flexDirection: 'column',
    },
});

interface Props {
    defaultValue: Partial<AppAlertFormValue>;
    onSubmit: (alert: AppAlertFormValue) => void;
    title: React.ReactNode;
    extraActions?: React.ReactNode;
}

const AppAlertForm: React.FC<Props> = ({ defaultValue, title, onSubmit, extraActions }) => {
    const classes = useStyles();

    const form = useForm<AppAlertFormType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: defaultValue,
    });

    const { handleSubmit } = form;

    return (
        <FormProvider {...form}>
            <HeadingWithActions>
                <Typography variant="h1">
                    {title}
                </Typography>
                {extraActions}
                <Button variant="outlined" component={Link} to="/maintenance">
                    <FormattedMessage id="alert-editor.cancel" />
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit((data) => {
                        onSubmit(data);
                    })}
                >
                    <FormattedMessage id="alert-editor.save" />
                </Button>
            </HeadingWithActions>

            <PeriodInput />

            <Typography variant="h2">
                <FormattedMessage id="app-alert.message" />
            </Typography>
            <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} lg={6} className={classes.messageInput}>
                    <AlertMessageInput language={LANG_HU} />
                </Grid>

                <Grid item xs={12} lg={6} className={classes.messageInput}>
                    <AlertMessageInput language={LANG_EN} />
                </Grid>
            </Grid>

            <AppVersionsInputPanel />
        </FormProvider>
    );
};

export default AppAlertForm;
