import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserPurchaseStatus } from '../../../graphql/graphql-typings';

interface Props {
    value: UserPurchaseStatus;
}

const PurchaseStatusText: React.FC<Props> = ({ value }) => {
    switch (value) {
        case UserPurchaseStatus.Ideiglenes:
            return <FormattedMessage id="purchase.status.temporary" />;
        case UserPurchaseStatus.Kifizetett:
            return <FormattedMessage id="purchase.status.paid" />;
        case UserPurchaseStatus.Torolt:
            return <FormattedMessage id="purchase.status.deleted" />;
        case UserPurchaseStatus.Visszavaltas:
            return <FormattedMessage id="purchase.status.storno" />;
        case UserPurchaseStatus.NmfrSztorno:
            return <FormattedMessage id="purchase.status.nmfr-storno" />;
        case UserPurchaseStatus.NmfrSztornoraVar:
            return <FormattedMessage id="purchase.status.nmfr-storno-waiting" />;
        case UserPurchaseStatus.Visszavalto:
            return <FormattedMessage id="purchase.status.stornoing" />;
    }
};

export default PurchaseStatusText;
