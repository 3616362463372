import React from 'react';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import { useCreateAppAlertMutation } from '../graphql/graphql-typings';
import AppAlertForm from './AlertEditor/AppAlertForm';
import type { AppAlertFormValue } from './AlertEditor/AppAlertFormValue';
import { alertInputFromFormValue } from './AlertEditor/alert-utils';
import AppAlertProgress from './AlertEditor/AppAlertProgress';

const CreateAlertForm: React.FC = () => {
    const [executeCreateAppAlert, mutationResult] = useCreateAppAlertMutation();

    const createAlert = (formValue: AppAlertFormValue) => {
        const alertInput = alertInputFromFormValue(formValue);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeCreateAppAlert({
            variables: {
                alertInput,
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
        });
    };

    return (
        <>
            <AppAlertForm
                defaultValue={{ startTime: DateTime.now(), endTime: null, forceUpdate: false, appVersions: '' }}
                title={<FormattedMessage id="alert-editor.creating-title" />}
                onSubmit={createAlert}
            />

            <AppAlertProgress
                mutationResult={mutationResult}
                finishedMessage={<FormattedMessage id="alert-editor.alert-created" />}
            />
        </>
    );
};

export default CreateAlertForm;
