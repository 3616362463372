/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportStatistics
 */

export interface ImportStatistics {
    /**
     * A létrehozott sorok száma
     * @type {number}
     * @memberof ImportStatistics
     */
    created: number;
    /**
     * A frissített sorok száma
     * @type {number}
     * @memberof ImportStatistics
     */
    updated: number;
    /**
     * A törölt sorok száma
     * @type {number}
     * @memberof ImportStatistics
     */
    deleted: number;
}

export function ImportStatisticsFromJSON(json: any): ImportStatistics {
    return ImportStatisticsFromJSONTyped(json, false);
}

export function ImportStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'created': json['created'],
        'updated': json['updated'],
        'deleted': json['deleted'],
    } as any;
}

export function ImportStatisticsToJSON(value?: ImportStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'created': value.created,
        'updated': value.updated,
        'deleted': value.deleted,
    };
}

