import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import type { PaginationState, SortingState, OnChangeFn } from '@tanstack/react-table';

type ReloadTableHandler = () => void;

interface PoisContextValue {
    selectedType: string | undefined;
    pagination: PaginationState;
    sorting: SortingState;
    setSelectedType: (type: string | undefined) => void;
    reloadTable: () => void;
    setReloadTableHandler: (handler: ReloadTableHandler) => () => void;
    setPagination: OnChangeFn<PaginationState>;
    setSorting: OnChangeFn<SortingState>;
}

export const PoisContext = createContext<PoisContextValue>(null as unknown as PoisContextValue);

export const PoisContextProvider: React.FC = ({ children }) => {
    const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
    const reloadTableHandler = useRef<ReloadTableHandler | null>(null);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 100,
    });
    const [sorting, setSorting] = useState<SortingState>([]);

    const reloadTable = useCallback(() => {
        if (!reloadTableHandler.current) {
            return;
        }
        reloadTableHandler.current();
    }, []);

    const setReloadTableHandler = useCallback((handler: ReloadTableHandler) => {
        reloadTableHandler.current = handler;
        return () => {
            reloadTableHandler.current = null;
        };
    }, []);

    const value = useMemo((): PoisContextValue => ({
        selectedType,
        pagination,
        sorting,
        setSelectedType,
        reloadTable,
        setReloadTableHandler,
        setPagination,
        setSorting,
    }), [selectedType, pagination, sorting, reloadTable, setReloadTableHandler]);

    return (
        <PoisContext.Provider value={value}>
            {children}
        </PoisContext.Provider>
    );
};
