import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import HeadingWithActions from '../../components/HeadingWithActions';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import MutationSuccessMessage from '../../components/MutationSuccessMessage';
import type { MessageInput } from '../../graphql/graphql-typings';
import { useSendMessageMutation } from '../../graphql/graphql-typings';
import Role from '../../Role';
import { translatedStrings } from '../../utils';
import AddresseeSelector from './AddresseeSelector';
import MessageTranslationForm from './MessageTranslationForm';
import type { SendMessageFormValue } from './SendMessageForm';
import { AddresseeType } from './SendMessageForm';

const SendMessage: React.FC = () => {
    const [executeSend, sendResult] = useSendMessageMutation();

    const form = useForm<SendMessageFormValue>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const { register, handleSubmit } = form;

    function sendMessage(value: SendMessageFormValue) {
        const message: MessageInput = {
            addressee: value.addresseeType === AddresseeType.ALL ? null : value.addresseeUser!.id,
            title: translatedStrings(value, 'title'),
            text: translatedStrings(value, 'text'),
            image: value.image || null,
            url: value.link || null,
        };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeSend({
            variables: {
                message,
            },
        });
    }

    return (
        <FormProvider {...form}>
            <HeadingWithActions>
                <Typography variant="h1">
                    <FormattedMessage id="send-message.title" />
                </Typography>
                <IfRole role={Role.NOTIFICATIONS_WRITE}>
                    <Button variant="contained" onClick={handleSubmit(sendMessage)}>
                        <FormattedMessage id="send-message.send" />
                    </Button>
                </IfRole>
            </HeadingWithActions>
            <IfRoleOrUnauthorizedFeatureMessage role={Role.NOTIFICATIONS_WRITE}>
                <AddresseeSelector />
                <Typography variant="h2">
                    <FormattedMessage id="send-message.message" />
                </Typography>
                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12} lg={6}>
                        <MessageTranslationForm language="hu" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MessageTranslationForm language="en" />
                    </Grid>
                </Grid>

                <TextField fullWidth margin="normal" label={<FormattedMessage id="message.link-url" />} {...register('link')} />
                <TextField fullWidth margin="normal" label={<FormattedMessage id="message.image-url" />} {...register('image')} />
            </IfRoleOrUnauthorizedFeatureMessage>

            <MutationProgressDialog
                mutationResult={sendResult}
                finishedDialogContent={(
                    <MutationSuccessMessage link="/messages">
                        <FormattedMessage id="send-message.message-sent" />
                    </MutationSuccessMessage>
                )}
            />
        </FormProvider>
    );
};

export default SendMessage;
