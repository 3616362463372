import React from 'react';
import ListQueryResultRenderer from '../../../components/ListQueryResultRenderer';
import { useListPurchasesQuery } from '../../../graphql/graphql-typings';
import UserPurchasesTable from './UserPurchasesTable';

interface Props {
    userId: string;
}

const UserPurchases: React.FC<Props> = ({ userId }) => {
    const queryResult = useListPurchasesQuery({
        fetchPolicy: 'network-only',
        variables: { userId },
    });

    return (
        <ListQueryResultRenderer
            queryResult={queryResult}
            component={UserPurchasesTable}
            property="purchases"
        />
    );
};

export default UserPurchases;
