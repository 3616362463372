import type { AsyncOptions } from 'react-async';
import { useAsync } from 'react-async';

type PromiseFnWithParams<P, T> = (props: AsyncOptionsWithParam<P, T>, controller: AbortController) => Promise<T>;

export interface AsyncOptionsWithParam<P, T> extends Omit<AsyncOptions<T>, 'promiseFn'> {
    promiseFn?: PromiseFnWithParams<P, T>;
    param: P;
}

export default function useAsyncWithParams<P, T>(options: AsyncOptionsWithParam<P, T>) {
    return useAsync({
        ...options as AsyncOptions<T>,
        watch: options.param,
    });
}
