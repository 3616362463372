import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PoiForm from './PoiForm';
import type { CreatePoiRequest, Poi } from '../generated/poi-service-internal';
import { PoiStatus } from '../generated/poi-service-internal';
import { ApiClientContext } from '../providers/ApiClientWrapper';
import useDeferredAsync from '../hooks/useDeferredAsync';
import MutationProgressDialog from '../components/MutationProgressDialog';
import MutationSuccessMessage from '../components/MutationSuccessMessage';
import { poiFormToCreatableData } from './PoiForm/poi-form-utils';
import type { PoiFormValue } from './PoiForm/PoiFormValue';

const EMPTY_POI_FORM: Omit<PoiFormValue, 'coordinates'> = {
    type: '',
    id: '',
    status: PoiStatus.DRAFT,
    operational: true,
    translations: { hu: { name: '', description: '' }, en: { name: '', description: '' } },
    openingHours: undefined,
};

const CreatePoiForm: React.FC = () => {
    const apiClient = useContext(ApiClientContext);

    // eslint-disable-next-line arrow-body-style
    const createPoi = useCallback(async (variables: CreatePoiRequest, controller: AbortController): Promise<Poi> => {
        return apiClient.createPoi(variables, { signal: controller.signal });
    }, [apiClient]);

    const [result, executeCreatePoi] = useDeferredAsync(createPoi);

    const finishedDialogContent = useCallback((poi: Poi) => (
        <MutationSuccessMessage link={`/poi/edit/${poi.id}`}>
            <FormattedMessage id="poi-form.poi-created" />
        </MutationSuccessMessage>
    ), []);

    return (
        <>
            <PoiForm
                title={<FormattedMessage id="poi-form.creating-title" />}
                defaultValue={EMPTY_POI_FORM}
                onSubmit={(formValue) => {
                    executeCreatePoi({
                        poiCreatableData: poiFormToCreatableData(formValue),
                    });
                }}
            />
            <MutationProgressDialog
                mutationResult={result}
                finishedDialogContent={finishedDialogContent}
            />
        </>
    );
};

export default CreatePoiForm;
