import React from 'react';
import { FormattedMessage } from 'react-intl';
import FavoriteTypeRenderer from '../../../../components/FavoriteType';
import type { ExtendedUserFavoriteItemFragment } from '../../../../graphql/graphql-typings';
import { TableDescriptor } from '../../../../components/TableView/TableDescriptor';
import FavoriteNameRenderer from '../FavoriteNameRenderer';
import TableView from '../../../../components/TableView';
import SubscriptionTimeRenderer from './SubscriptionTimeRenderer';

const DESCRIPTOR = new TableDescriptor<ExtendedUserFavoriteItemFragment>()
    .column(
        <FormattedMessage id="users.favorites.type" />,
        'favorite',
        favorite => favorite ? <FavoriteTypeRenderer type={favorite.type} /> : <FormattedMessage id="users.favorites.type.subscription" />,
    )
    .customColumn({
        header: <FormattedMessage id="users.favorites.name" />,
        getValue: favoriteItem => favoriteItem,
        renderCell: ({ favorite, subscription }) => favorite ? <FavoriteNameRenderer favorite={favorite} /> : subscription!.routeId,
    })
    .column(<FormattedMessage id="users.favorites.period" />, 'subscription', s => s && <SubscriptionTimeRenderer times={s.time} />)
    .column(<FormattedMessage id="users.favorites.excluded-effects" />, 'subscription', s => s?.excludedEffects.join(','))
    .column(<FormattedMessage id="users.favorites.stop-ids" />, 'subscription', subscription => subscription?.stopIds.join(','));

interface Props {
    extendedFavorites: ExtendedUserFavoriteItemFragment[];
}

const ExtendedUserFavoritesTable: React.FC<Props> = ({ extendedFavorites }) => (
    <TableView
        descriptor={DESCRIPTOR}
        items={extendedFavorites}
    />
);

export default ExtendedUserFavoritesTable;
