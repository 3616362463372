import type { RowSelectionState } from '@tanstack/react-table';
// eslint-disable-next-line camelcase
import type { MRT_ColumnDef } from 'material-react-table';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import RequestErrorMessage from '@realcity/web-frame/lib/components/Message/RequestErrorMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useMemoOne } from 'use-memo-one';
import IconButton from '@mui/material/IconButton';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import type { GetPoisRequest, OpeningHours, Poi, Pois, PoiSortBy, PoiStatus } from '../../generated/poi-service-internal';
import { SortOrder } from '../../generated/poi-service-internal';
import useMRTLocalization from './useMRTLocalization';
import { ApiClientContext } from '../../providers/ApiClientWrapper';
import { POI_TYPES } from '../../config';
import PoiOperationalChip from './PoiOperationalChip';
import PoiStatusChip from './PoiStatusChip';
import type { AsyncOptionsWithParam } from '../../hooks/useAsyncWithParams';
import useAsyncWithParams from '../../hooks/useAsyncWithParams';
import { getOpeningHoursType, translatePoiType } from '../PoiForm/poi-form-utils';
import OpeningHoursTypeText from '../PoiForm/OpeningHoursEditor/OpeningHoursTypeText';
import PoiBatchOperations from './PoiBatchOperations';
import Role from '../../Role';
import { PoisContext } from './PoisContext';
import FormattedDateTime from '../../components/FormattedDateTime';

const EMPTY_POI_LIST: Poi[] = [];

// eslint-disable-next-line camelcase
const COLUMNS: MRT_ColumnDef<Poi>[] = [{
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.type" />,
    accessorKey: 'type',
    enableSorting: false,
    enableColumnActions: false,
    Cell: ({ cell }) => {
        const intl = useIntl();

        const value = cell.getValue();
        for (const poitype of POI_TYPES) {
            if (poitype.value === value) {
                return translatePoiType(intl, poitype);
            }
        }
        return value;
    },
}, {
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.id" />,
    accessorKey: 'id',
}, {
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.name" />,
    accessorKey: 'name.hu',
    id: 'name',
}, {
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.opening-hours" />,
    accessorKey: 'openingHours',
    enableSorting: false,
    enableColumnActions: false,
    Cell: ({ cell }) => {
        const openingHoursType = getOpeningHoursType(cell.getValue() as OpeningHours | undefined);
        return <OpeningHoursTypeText type={openingHoursType} />;
    },
}, {
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.operational" />,
    accessorKey: 'operational',
    Cell: ({ cell }) => <PoiOperationalChip operational={cell.getValue() as boolean} />,
}, {
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.status" />,
    accessorKey: 'status',
    Cell: ({ cell }) => <PoiStatusChip status={cell.getValue() as PoiStatus} />,
}, {
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.created-time" />,
    accessorKey: 'createdTime',
    Cell: ({ cell }) => <FormattedDateTime value={cell.getValue() as Date} />,
}, {
    header: '',
    Header: () => <FormattedMessage id="poi-editor.poi.modified-time" />,
    accessorKey: 'modifiedTime',
    Cell: ({ cell }) => <FormattedDateTime value={cell.getValue() as Date} />,
}];

const PoiTable: React.FC = () => {
    const intl = useIntl();
    const apiClient = useContext(ApiClientContext);
    const {
        selectedType,
        pagination,
        sorting,
        setSelectedType,
        setReloadTableHandler,
        setPagination,
        setSorting,
    } = useContext(PoisContext);

    const localization = useMRTLocalization();

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    // eslint-disable-next-line arrow-body-style
    const getPois = useCallback(async (props: AsyncOptionsWithParam<GetPoisRequest, Pois>, controller: AbortController): Promise<Pois> => {
        return apiClient.getPois(props.param, { signal: controller.signal });
    }, [apiClient]);

    const getPoisRequest = useMemoOne((): GetPoisRequest => {
        const sortOptions: Partial<GetPoisRequest> = sorting.length === 0 ? {} : {
            sortBy: sorting[0].id as PoiSortBy,
            sortOrder: sorting[0].desc ? SortOrder.DESC : SortOrder.ASC,
        };

        return {
            type: selectedType,
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
            ...sortOptions,
        };
    }, [selectedType, pagination, sorting]);

    const res = useAsyncWithParams({
        promiseFn: getPois,
        param: getPoisRequest,
    });

    const list = res.status === 'fulfilled' ? res.data.list : EMPTY_POI_LIST;
    const total = res.status === 'fulfilled' ? res.data.total : pagination.pageIndex * pagination.pageSize + 1;

    const rowSelectionOnPage = useMemo((): RowSelectionState => {
        const result: RowSelectionState = {};
        for (const row of list) {
            if (rowSelection[row.id]) {
                result[row.id] = true;
            }
        }
        return result;
    }, [rowSelection, list]);

    useEffect(() => {
        if (res.status !== 'fulfilled') {
            return;
        }
        return setReloadTableHandler(res.reload);
    }, [setReloadTableHandler, res]);

    if (res.status === 'rejected') {
        return <RequestErrorMessage />;
    }

    return (
        <>
            <Stack direction="row" alignItems="baseline">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel shrink>
                        <FormattedMessage id="poi-editor.poi.type" />
                    </InputLabel>
                    <Select
                        value={selectedType ?? ''}
                        onChange={(e) => {
                            setSelectedType(e.target.value || undefined);
                        }}
                        displayEmpty
                        label={<FormattedMessage id="poi-editor.poi.type" />}
                        sx={{ minWidth: 180 }}
                    >
                        <MenuItem value="">
                            <FormattedMessage id="poi-editor.type-filter.empty" />
                        </MenuItem>
                        {POI_TYPES.map(c => (
                            <MenuItem key={c.value} value={c.value}>
                                {translatePoiType(intl, c)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <IfRole role={Role.POI_WRITE}>
                    <PoiBatchOperations selectedIds={rowSelectionOnPage} />
                </IfRole>
            </Stack>
            <MaterialReactTable
                localization={localization}
                columns={COLUMNS}
                data={list}
                rowCount={total}
                state={{ pagination, sorting, isLoading: res.status !== 'fulfilled', rowSelection: rowSelectionOnPage }}
                manualPagination
                onPaginationChange={setPagination}
                manualSorting
                onSortingChange={setSorting}
                enableRowSelection
                getRowId={row => row.id}
                onRowSelectionChange={setRowSelection}
                enableGlobalFilter={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableFilters={false}
                enableTopToolbar={false}
                enableRowActions
                positionActionsColumn="last"
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '',
                    },
                }}
                renderRowActions={({ row }) => (
                    <IconButton component={Link} to={`/poi/edit/${row.original.id}`}>
                        <EditOutlined />
                    </IconButton>
                )}
            />
        </>
    );
};

export default PoiTable;
