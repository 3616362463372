import Chip from '@mui/material/Chip';
import React from 'react';

interface Props {
    providerName: string;
    userName: string | null;
}

function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const IdentityProvider: React.FC<Props> = ({ providerName, userName }) => {
    const label = `${capitalize(providerName)}${userName ? `: ${userName}` : ''}`;
    return (
        <Chip variant="outlined" sx={{ marginRight: 2 }} label={label} />
    );
};

export default IdentityProvider;
