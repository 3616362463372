import React from 'react';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ApiKeyEntryRow from './ApiKeyEntryRow';
import type { GroupedApiKeys } from '../../alert-utils';

let nextId = 1;

interface Props {
    value: GroupedApiKeys;
    onChange: (value: GroupedApiKeys) => void;
}

const AppVersionsSelector = ({ value, onChange }: Props) => {
    const modify = <K extends keyof GroupedApiKeys>(key: K, id: string, update: Partial<GroupedApiKeys[K][0]>) => {
        const modified = {
            ...value,
            [key]: value[key].map(p => p.id === id ? { ...p, ...update } : p),
        };
        onChange(modified);
    };

    return (
        <Table padding="none" sx={{ width: 'initial', mt: 2, 'td, th': { borderBottom: 'none', '&:first-of-type': { pl: 0 } } }}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <FormattedMessage id="app-versions-selector.enabled" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="app-versions-selector.api-key" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="app-versions-selector.versions" />
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {value.predefined.map(entry => (
                    <ApiKeyEntryRow
                        key={entry.id}
                        value={entry}
                        onUpdateValue={(id, update) => {
                            modify('predefined', id, update);
                        }}
                        apiKeyCell={(
                            <Tooltip title={entry.apiKey}>
                                <Typography>
                                    {entry.name}
                                </Typography>
                            </Tooltip>
                        )}
                    />
                ))}
                {value.custom.map(entry => (
                    <ApiKeyEntryRow
                        key={entry.id}
                        value={entry}
                        onUpdateValue={(id, update) => {
                            modify('custom', id, update);
                        }}
                        apiKeyCell={(
                            <TextField
                                margin="dense"
                                size="small"
                                value={entry.apiKey}
                                onChange={(e) => {
                                    modify('custom', entry.id, { apiKey: e.target.value.trim() });
                                }}
                            />
                        )}
                        extraAction={(
                            <Tooltip title={<FormattedMessage id="app-versions-selector.remove-tooltip" />}>
                                <IconButton
                                    onClick={() => {
                                        onChange({
                                            ...value,
                                            custom: value.custom.filter(key => key.id !== entry.id),
                                        });
                                    }}
                                >
                                    <Remove />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                ))}
                <TableRow>
                    <TableCell align="center">
                        <Tooltip title={<FormattedMessage id="app-versions-selector.add-tooltip" />}>
                            <IconButton
                                onClick={() => {
                                    onChange({
                                        ...value,
                                        custom: [...value.custom, { id: createApiKeyEntryId(), enabled: true, apiKey: '', versions: [] }],
                                    });
                                }}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell colSpan={3} />
                </TableRow>
            </TableBody>
        </Table>
    );
};

export function createApiKeyEntryId(): string {
    return (nextId++).toString();
}

export default AppVersionsSelector;
