import React, { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import type ColumnFilter from './ColumnFilter';
import type FilterProvider from './FilterProvider';

interface PossibleValue<T> {
    value: T;
    available: boolean;
}

interface Props<T, R> {
    filter: ColumnFilter<T, R>;
    filterProvider: FilterProvider<T>;
}

const ColumnFilterSelectorMenuItems = <T, R>({ filter, filterProvider }: Props<T, R>) => {
    const { column, selected } = filter;
    const { getFilteredItems, columnFilters, onColumnFilterChange } = filterProvider;

    const possibleValues = useMemo(() => {
        const allItems = getFilteredItems();
        const selectableValues = Array.from(new Set(allItems.map(row => column.getValue(row))));
        selectableValues.sort((a, b) => String(a).localeCompare(String(b)));

        const otherFilters = columnFilters.filter(f => f.column !== column);
        const availableValues = new Set(getFilteredItems(otherFilters).map(row => column.getValue(row)));

        return selectableValues.map<PossibleValue<R>>(value => ({
            value,
            available: availableValues.has(value),
        })).sort((a, b) => Number(b.available) - Number(a.available));
    }, [columnFilters, column, getFilteredItems]);

    const handleChange = (itemValue: R) => {
        if (selected.includes(itemValue)) {
            onColumnFilterChange(column, selected.filter(v => v !== itemValue));
        } else {
            onColumnFilterChange(column, [...selected, itemValue]);
        }
    };

    return (
        <>
            {possibleValues.map(({ value, available }) => (
                <MenuItem
                    key={value as unknown as number | string}
                    dense
                    sx={{ opacity: available ? 1 : 0.35 }}
                    onClick={() => {
                        handleChange(value);
                    }}
                >
                    <Checkbox checked={selected.includes(value)} />
                    <ListItemText primary={column.renderCell(value)} />
                </MenuItem>
            ))}
        </>
    );
};

export default ColumnFilterSelectorMenuItems;
