import type { ReactNode } from 'react';
import React from 'react';
import Chip from '@mui/material/Chip';
import { FormattedMessage } from 'react-intl';

interface Props {
    operational: boolean;
}

const PoiOperationalChip: React.FC<Props> = ({ operational }) => (
    <Chip
        label={getOperationalText(operational)}
        sx={{ height: 21, color: '#fff', backgroundColor: getOperationalColor(operational) }}
    />
);

function getOperationalText(operational: boolean): ReactNode {
    return operational ? (
        <FormattedMessage id="poi-editor.poi.operational.yes" />
    ) : (
        <FormattedMessage id="poi-editor.poi.operational.no" />
    );
}

function getOperationalColor(operational: boolean): string {
    return operational ? '#219653' : '#E31B0C';
}

export default PoiOperationalChip;
