/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpeningExceptionAllOf,
    OpeningExceptionAllOfFromJSON,
    OpeningExceptionAllOfFromJSONTyped,
    OpeningExceptionAllOfToJSON,
    OpeningInterval,
    OpeningIntervalFromJSON,
    OpeningIntervalFromJSONTyped,
    OpeningIntervalToJSON,
} from './';




/**
 * 
 * @export
 * @interface OpeningException
 */

export interface OpeningException {
    /**
     * Kezdő időpont másodpercben éjféltől számítva
     * @type {number}
     * @memberof OpeningException
     */
    from: number;
    /**
     * Vég időpont másodpercben éjféltől számítva
     * @type {number}
     * @memberof OpeningException
     */
    to: number;
    /**
     * 
     * @type {Date}
     * @memberof OpeningException
     */
    date: Date;
}

export function OpeningExceptionFromJSON(json: any): OpeningException {
    return OpeningExceptionFromJSONTyped(json, false);
}

export function OpeningExceptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningException {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'from': json['from'],
        'to': json['to'],
        'date': (new Date(json['date'])),
    } as any;
}

export function OpeningExceptionToJSON(value?: OpeningException | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'from': value.from,
        'to': value.to,
        'date': (value.date.toISOString().substr(0,10)),
    };
}

