/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC'
}

export function SortOrderFromJSON(json: any): SortOrder {
    return SortOrderFromJSONTyped(json, false);
}

export function SortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortOrder {
    return json as SortOrder;
}

export function SortOrderToJSON(value?: SortOrder | null): any {
    return value as any;
}

