import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react';
import { FormattedDisplayName, FormattedMessage } from 'react-intl';
import type ReactQuill from 'react-quill';
import { useController, useFormContext } from 'react-hook-form';
import type { AppAlertFormType } from './AppAlertFormValue';
import HtmlInput from '../../components/HtmlInput';

interface Props {
    language: 'en' | 'hu';
}

const AlertMessageInput: React.FC<Props> = ({ language }) => {
    const quillRef = useRef<ReactQuill | null>(null);

    const { register, control, formState: { errors } } = useFormContext<AppAlertFormType>();
    const descriptionFieldName = `${language}.description` as const;
    const { field: descriptionField } = useController<AppAlertFormType, typeof descriptionFieldName>({
        name: descriptionFieldName,
        rules: { validate: () => validateDescription(quillRef.current!) },
        control,
    });

    return (
        <>
            <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
                <FormattedDisplayName value={language} type="language" />
            </Typography>

            <TextField
                required
                fullWidth
                margin="normal"
                label={<FormattedMessage id="app-alert.summary" />}
                error={Boolean(errors[language]?.summary)}
                {...register(`${language}.summary`, { required: true })}
            />

            <FormLabel required>
                <FormattedMessage id="app-alert.description" />
            </FormLabel>
            <HtmlInput
                {...descriptionField}
                error={Boolean(errors[language]?.description)}
                ref={quillRef}
            />

            <TextField
                fullWidth
                margin="normal"
                label={<FormattedMessage id="app-alert.url" />}
                {...register(`${language}.url`)}
            />
        </>
    );
};

function validateDescription(quill: ReactQuill): boolean {
    const editor = quill.getEditor();
    return editor.getText().trim() !== ''
        || editor.root.querySelector('img') !== null;
}

export default AlertMessageInput;
