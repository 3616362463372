import React from 'react';
import ListQueryResultRenderer from '../../../components/ListQueryResultRenderer';
import { useListFavoritesQuery } from '../../../graphql/graphql-typings';
import UserFavoritesTable from './UserFavoritesTable';

interface Props {
    userId: string;
}

const SimpleUserFavorites: React.FC<Props> = ({ userId }) => {
    const queryResult = useListFavoritesQuery({
        fetchPolicy: 'network-only',
        variables: { userId },
    });

    return (
        <ListQueryResultRenderer
            queryResult={queryResult}
            component={UserFavoritesTable}
            property="favorites"
        />
    );
};

export default SimpleUserFavorites;
