import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FavoriteType } from '../graphql/graphql-typings';

interface Props {
    type: FavoriteType;
}

const FavoriteTypeRenderer: React.FC<Props> = ({ type }) => {
    switch (type) {
        case FavoriteType.Stop:
            return <FormattedMessage id="users.favorites.stop" />;
        case FavoriteType.Route:
            return <FormattedMessage id="users.favorites.route" />;
        case FavoriteType.OfflineMap:
            return <FormattedMessage id="users.favorites.map" />;
        case FavoriteType.PlaceHome:
            return <FormattedMessage id="users.favorites.home" />;
        case FavoriteType.PlaceWork:
            return <FormattedMessage id="users.favorites.work" />;
        case FavoriteType.PlaceOther:
            return <FormattedMessage id="users.favorites.other-place" />;
    }
};

export default FavoriteTypeRenderer;
