import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ManagedUserStatus } from '../graphql/graphql-typings';

interface Props {
    status: ManagedUserStatus;
}

const UserStatus: React.FC<Props> = ({ status }) => {
    if (status === ManagedUserStatus.Active) {
        return <FormattedMessage id="users.status.active" />;
    }

    if (status === ManagedUserStatus.Disabled) {
        return <FormattedMessage id="users.status.disabled" />;
    }

    return <FormattedMessage id="users.status.deleted" />;
};

export default UserStatus;
