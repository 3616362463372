import React, { useState } from 'react';
import FilterAlt from '@mui/icons-material/FilterAlt';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import type ColumnFilter from './ColumnFilter';
import ColumnFilterSelectorMenuItems from './ColumnFilterSelectorMenuItems';
import type FilterProvider from './FilterProvider';

interface Props<T, R> {
    filter: ColumnFilter<T, R>;
    filterProvider: FilterProvider<T>;
}

const ColumnFilterSelector = <T, R>({ filter, filterProvider }: Props<T, R>) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    return (
        <>
            <IconButton
                onClick={(e) => {
                    setMenuAnchor(e.currentTarget);
                }}
            >
                {filter.selected.length === 0 ? <FilterAltOutlined /> : <FilterAlt />}
            </IconButton>
            <Menu
                open={menuAnchor !== null}
                onClose={() => {
                    setMenuAnchor(null);
                }}
                anchorEl={menuAnchor}
            >
                <ColumnFilterSelectorMenuItems filter={filter} filterProvider={filterProvider} />
            </Menu>
        </>
    );
};

export default ColumnFilterSelector;
