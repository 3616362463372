import { DateTime } from 'luxon';
import type { AppAlertFormValue, AlertMessageFormValue } from './AppAlertFormValue';
import type {
    AlertInput,
    AppAlertDetailsFragment,
    AppVersionAndApiKeyFragment,
    TranslatedStringFragment,
} from '../../graphql/graphql-typings';
import { affectedVersionsToString, translatedStrings } from '../../utils';

export const LANG_HU = 'hu';
export const LANG_EN = 'en';

export function alertFromFragment(alertFragment: AppAlertDetailsFragment): AppAlertFormValue {
    return {
        startTime: DateTime.fromISO(alertFragment.startTime),
        endTime: alertFragment.endTime ? DateTime.fromISO(alertFragment.endTime) : null,
        hu: messageInputFromFragment(alertFragment, LANG_HU),
        en: messageInputFromFragment(alertFragment, LANG_EN),
        forceUpdate: alertFragment.forceUpdate,
        appVersions: affectedVersionsToString(alertFragment.appVersions),
    };
}

function messageInputFromFragment(alertFragment: AppAlertDetailsFragment, locale: string): AlertMessageFormValue {
    return {
        summary: findLocaleOrDefault(alertFragment.summary, locale),
        description: findLocaleOrDefault(alertFragment.description, locale),
        url: findLocaleOrDefault(alertFragment.url, locale),
    };
}

function findLocaleOrDefault(translations: TranslatedStringFragment[], locale: string) {
    return translations.find(e => e.locale === locale)?.text || '';
}

export function alertInputFromFormValue(formValue: AppAlertFormValue): AlertInput {
    return {
        startTime: formValue.startTime.toISO(),
        endTime: formValue.endTime ? formValue.endTime.toISO() : null,
        summary: translatedStrings(formValue, 'summary'),
        description: translatedStrings(formValue, 'description'),
        url: translatedStrings(formValue, 'url'),
        forceUpdate: formValue.forceUpdate,
        appAndVersions: parseAppAndVersions(formValue.appVersions)!,
    };
}

export function parseAppAndVersions(affectedVersions: string): AppVersionAndApiKeyFragment[] | null {
    if (affectedVersions === '') {
        return [];
    }

    const parsedAffectedVersions: AppVersionAndApiKeyFragment[] = [];
    const splitAffectedVersions = affectedVersions.split(',');

    for (const affectedVersion of splitAffectedVersions) {
        if (affectedVersion === '') {
            return null;
        }

        parsedAffectedVersions.push(parseAppAndVersion(affectedVersion));
    }

    return parsedAffectedVersions;
}

function parseAppAndVersion(appAndVersion: string): AppVersionAndApiKeyFragment {
    const separatorIndex = appAndVersion.indexOf(':');

    if (separatorIndex === -1) {
        return { apiKey: appAndVersion, appVersion: null };
    }

    return {
        apiKey: appAndVersion.substring(0, separatorIndex),
        appVersion: appAndVersion.substring(separatorIndex + 1),
    };
}
