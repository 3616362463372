import React from 'react';
import { useParams } from 'react-router-dom';
import QueryResultRenderer from '../../components/QueryResultRenderer';
import { useGetUserQuery } from '../../graphql/graphql-typings';
import UserDetailsPanel from './UserDetailsPanel';

const UserDetails: React.FC = () => {
    const userId = useParams<'userId'>().userId!;

    const queryResult = useGetUserQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: { id: userId },
    });

    return (
        <QueryResultRenderer
            queryResult={queryResult}
            component={UserDetailsPanel}
        />
    );
};

export default UserDetails;
