import Button from '@mui/material/Button';
import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormattedMessage } from 'react-intl';
import type { ReactElement, ReactNode } from 'react';
import React, { useCallback, useState } from 'react';

export interface UseConfirmParams<T> {
    onConfirm: (value: T) => void;
    dialogTitle: ReactNode;
    dialogContent: ReactNode;
    confirmDisabled?: boolean;
    dialogProps?: Omit<DialogProps, 'onClose' | 'open'>;
}

type UseConfirmResult<T> = [confirmDialog: ReactElement, showConfirm: (value: T) => void];

const useConfirm = <T extends object>(props: UseConfirmParams<T>): UseConfirmResult<T> => {
    const { onConfirm, dialogTitle, dialogContent, confirmDisabled, dialogProps } = props;
    const [value, setValue] = useState<T | null>(null);

    const handleConfirm = () => {
        if (!value) {
            return;
        }
        onConfirm(value);
        setValue(null);
    };

    const handleClose = useCallback(() => {
        setValue(null);
    }, []);

    const confirmDialog = (
        <Dialog {...dialogProps} open={value !== null} onClose={handleClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>{dialogContent}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    <FormattedMessage id="confirmation.cancel" />
                </Button>
                <Button variant="contained" onClick={handleConfirm} disabled={confirmDisabled}>
                    <FormattedMessage id="confirmation.ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );

    return [confirmDialog, setValue];
};

export default useConfirm;
