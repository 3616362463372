import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { FormattedMessage } from 'react-intl';
import type { DayOfWeek } from '../../../generated/poi-service-internal';
import { DAYS } from './constants';
import DayOfWeekText from './DayOfWeekText';

interface Props {
    open: boolean;
    onOk: (days: DayOfWeek[]) => void;
    onClose: () => void;
}

const CopyIntervalsDialog = ({ open, onOk, onClose }: Props) => {
    const [selected, setSelected] = useState<DayOfWeek[]>([]);

    const handleOk = () => {
        onOk(selected);
        setSelected([]);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage id="poi-editor.poi.opening-hours.copy" />
            </DialogTitle>
            <DialogContent>
                {DAYS.map(day => (
                    <FormGroup key={day}>
                        <FormControlLabel
                            control={<Checkbox checked={selected.includes(day)} />}
                            onChange={(event, checked) => {
                                if (checked) {
                                    setSelected([...selected, day]);
                                } else {
                                    setSelected(selected.filter(d => d !== day));
                                }
                            }}
                            label={<DayOfWeekText day={day} />}
                        />
                    </FormGroup>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>
                    <FormattedMessage id="message-dialog.ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CopyIntervalsDialog;
