import type { AppVersionAndApiKeyFragment } from './graphql/graphql-typings';
import { createApiKeyEntryId } from './scenes/AlertEditor/AppVersionsSelector';
import { PREDEFINED_KEYS } from './config';

export interface ApiKeyEntry {
    id: string;
    enabled: boolean;
    apiKey: string;
    versions: string[];
}

interface PredefinedApiKeyEntry extends ApiKeyEntry {
    name: string;
}

export interface GroupedApiKeys {
    predefined: PredefinedApiKeyEntry[];
    custom: ApiKeyEntry[];
}

export function groupApiKeysIfHas(entries: AppVersionAndApiKeyFragment[] | null): GroupedApiKeys | null {
    if (!entries) {
        return null;
    }
    return groupApiKeys(entries);
}

export function groupApiKeys(entries: AppVersionAndApiKeyFragment[]): GroupedApiKeys {
    const predefined: GroupedApiKeys['predefined'] = [];
    for (const { apiKey, name } of PREDEFINED_KEYS) {
        const entriesForPredefinedKey = entries.filter(a => a.apiKey === apiKey);
        predefined.push({
            id: createApiKeyEntryId(),
            apiKey,
            name,
            versions: entriesForPredefinedKey.map(a => a.appVersion).filter((a): a is string => a !== null),
            enabled: entriesForPredefinedKey.length !== 0,
        });
    }

    const custom: GroupedApiKeys['custom'] = [];
    const customEntries = entries.filter(({ apiKey }) => !PREDEFINED_KEYS.some(p => p.apiKey === apiKey));

    for (const customEntry of customEntries) {
        if (!customEntry.apiKey) {
            continue;
        }
        let existing = custom.find(c => c.apiKey === customEntry.apiKey);
        if (!existing) {
            existing = {
                id: createApiKeyEntryId(),
                apiKey: customEntry.apiKey,
                versions: [],
                enabled: true,
            };
            custom.push(existing);
        }
        if (customEntry.appVersion !== null) {
            existing.versions.push(customEntry.appVersion);
        }
    }
    return { predefined, custom };
}

export function ungroupApiKeys(groupedApiKeys: GroupedApiKeys): AppVersionAndApiKeyFragment[] {
    const result: AppVersionAndApiKeyFragment[] = [];

    const entries = [...groupedApiKeys.predefined, ...groupedApiKeys.custom].filter(p => p.enabled);

    for (const groupedApiKey of entries) {
        if (!groupedApiKey.apiKey) {
            continue;
        }

        if (groupedApiKey.versions.length === 0) {
            result.push({ apiKey: groupedApiKey.apiKey, appVersion: null });
        } else {
            for (const appVersion of groupedApiKey.versions) {
                result.push({ apiKey: groupedApiKey.apiKey, appVersion });
            }
        }
    }

    return result;
}
