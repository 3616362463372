import Chip from '@mui/material/Chip';
import React from 'react';
import { UserMessageStatus } from '../../../graphql/graphql-typings';

interface Props {
    value: UserMessageStatus;
}

const UserMessageStatusChip: React.FC<Props> = ({ value }) => {
    const [color, backgroundColor] = getStatusDisplayInfo(value);
    return (
        <Chip label={value} sx={{ color, backgroundColor }} />
    );
};

function getStatusDisplayInfo(status: UserMessageStatus): [color: string, backgroundColor: string] {
    switch (status) {
        case UserMessageStatus.Pending:
            return ['#fff', '#2f80ed'];
        case UserMessageStatus.NoToken:
            return ['#31093d', '#f7b478'];
        case UserMessageStatus.Failure:
            return ['#fff', '#eb5757'];
        case UserMessageStatus.Success:
            return ['#fff', '#219653'];
    }
}

export default UserMessageStatusChip;
