import MoreHoriz from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import DropDownMenu from '@realcity/web-frame/lib/components/DropDownMenu';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import MutationSuccessMessage from '../../components/MutationSuccessMessage';
import { useDeleteAlertMutation } from '../../graphql/graphql-typings';

interface Props {
    alertId: string;
}

const UpdateAlertFormExtraActions: React.FC<Props> = ({ alertId }) => {
    const [executeDeleteAppAlert, mutationResult] = useDeleteAlertMutation();

    const deleteAppAlert = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeDeleteAppAlert({
            variables: {
                alertId,
            },
        });
    };

    return (
        <>
            <DropDownMenu buttonContent={<MoreHoriz />} horizontalAlignment="right">
                <MenuItem onClick={deleteAppAlert}>
                    <FormattedMessage id="maintenance.delete" />
                </MenuItem>
            </DropDownMenu>

            <MutationProgressDialog
                mutationResult={mutationResult}
                finishedDialogContent={(
                    <MutationSuccessMessage link="/maintenance">
                        <FormattedMessage id="alert-editor.alert-deleted" />
                    </MutationSuccessMessage>
                )}
            />
        </>

    );
};

export default UpdateAlertFormExtraActions;
