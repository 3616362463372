import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedDateTime from '../components/FormattedDateTime';
import TableDataView from '../components/TableDataView';
import { TableDescriptor } from '../components/TableView/TableDescriptor';
import UserLink from '../components/UserLink';
import UserStatus from '../components/UserStatus';
import type { ListUsersQuery } from '../graphql/graphql-typings';
import { useListUsersQuery } from '../graphql/graphql-typings';
import { MIN_SEARCH_LENGTH, useThrottledSearch } from '../hooks/useThrottledSearch';
import Role from '../Role';

type ListUsersQueryData = ListUsersQuery['listUsers']['items'][0];

const DESCRIPTOR = new TableDescriptor<ListUsersQueryData>()
    .column(<FormattedMessage id="users.customer-id" />, 'customerId')
    .customColumn({
        header: <FormattedMessage id="users.name" />,
        getValue: user => user,
        renderCell: user => <UserLink user={user} />,
    })
    .column(<FormattedMessage id="users.email" />, 'email')
    .customColumn({
        header: <FormattedMessage id="users.registered-at" />,
        align: 'center',
        getValue: user => user.registeredAt,
        renderCell: registeredAt => <FormattedDateTime value={registeredAt} />,
    })
    .column(<FormattedMessage id="users.status" />, 'status', status => <UserStatus status={status} />);

const Users: React.FC = () => {
    const { search, setSearch, throttledSearch } = useThrottledSearch();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    return (
        <>
            <Typography variant="h1">
                <FormattedMessage id="users.title" />
            </Typography>
            <IfRoleOrUnauthorizedFeatureMessage role={Role.USERS_READ}>
                <TextField
                    helperText={<FormattedMessage id="search.too-short" values={{ characters: MIN_SEARCH_LENGTH }} />}
                    value={search}
                    onChange={handleSearchChange}
                    sx={{ width: 500 }}
                    label={<FormattedMessage id="users.search" />}
                    fullWidth
                />
                <TableDataView
                    descriptor={DESCRIPTOR}
                    query={useListUsersQuery}
                    queryOptions={{ variables: { search: throttledSearch } }}
                    dataName="listUsers"
                />
            </IfRoleOrUnauthorizedFeatureMessage>
        </>
    );
};

export default Users;
