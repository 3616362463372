import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        '& > :first-child': {
            flex: 1,
        },
        '& > :not(:last-child)': {
            marginRight: 12,
        },
    },
});

const HeadingWithActions: React.FC = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
};

export default HeadingWithActions;
