import React, { useCallback, useContext } from 'react';
import type { RowSelectionState } from '@tanstack/table-core/src/features/RowSelection';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import DropDownMenu from '@realcity/web-frame/lib/components/DropDownMenu';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PoiOperationalChip from './PoiOperationalChip';
import type { PoiBatchUpdate } from '../../generated/poi-service-internal';
import { PoiStatus } from '../../generated/poi-service-internal';
import PoiStatusChip from './PoiStatusChip';
import { ApiClientContext } from '../../providers/ApiClientWrapper';
import useDeferredAsync from '../../hooks/useDeferredAsync';
import ConfirmButton from '../../components/ConfirmButton';
import MutationProgressDialog from '../../components/MutationProgressDialog';
import useConfirm from '../../hooks/useConfirm';
import { POI_OPERATIONAL_VALUES, POI_STATUSES } from '../PoiForm/OpeningHoursEditor/constants';
import { PoisContext } from './PoisContext';

interface Props {
    selectedIds: RowSelectionState;
}

const PoiBatchOperations: React.FC<Props> = ({ selectedIds }) => {
    const apiClient = useContext(ApiClientContext);
    const { reloadTable } = useContext(PoisContext);

    const batchUpdate = useCallback(async (variables: PoiBatchUpdate, controller: AbortController): Promise<{}> => {
        await apiClient.batchUpdate({ poiBatchUpdate: variables }, controller);
        return {};
    }, [apiClient]);

    const [result, executeBatchUpdate] = useDeferredAsync(batchUpdate);

    const [confirmDialog, showUpdateConfirm] = useConfirm<PoiBatchUpdate>({
        onConfirm: executeBatchUpdate,
        dialogTitle: <FormattedMessage id="poi-editor.batch-update.confirm" />,
        dialogContent: null,
    });

    const ids = Object.keys(selectedIds).filter(id => selectedIds[id]);
    const rows = ids.length;
    const batchOperationDisabled = rows === 0;

    return (
        <Stack direction="row" alignItems="baseline" spacing={2}>
            <Typography sx={{ width: 140, textAlign: 'right' }}>
                <FormattedMessage id="poi-editor.selection-count" values={{ rows }} />
            </Typography>
            <ConfirmButton
                color="error"
                disabled={batchOperationDisabled}
                dialogTitle={<FormattedMessage id="poi-editor.delete.confirm" />}
                dialogContent={null}
                onConfirm={() => {
                    executeBatchUpdate({
                        ids,
                        status: PoiStatus.DELETED,
                    });
                }}
            >
                <FormattedMessage id="poi-editor.delete" />
            </ConfirmButton>
            <DropDownMenu
                disabled={batchOperationDisabled}
                buttonContent={(
                    <>
                        <FormattedMessage id="poi-editor.poi.operational" />
                        <ArrowDropDown />
                    </>
                )}
            >
                {POI_OPERATIONAL_VALUES.map(operational => (
                    <MenuItem
                        key={operational.toString()}
                        value={operational as any}
                        onClick={() => {
                            showUpdateConfirm({ ids, operational });
                        }}
                    >
                        <PoiOperationalChip operational={operational} />
                    </MenuItem>
                ))}
            </DropDownMenu>
            <DropDownMenu
                disabled={batchOperationDisabled}
                buttonContent={(
                    <>
                        <FormattedMessage id="poi-editor.poi.status" />
                        <ArrowDropDown />
                    </>
                )}
            >
                {POI_STATUSES.map(status => (
                    <MenuItem
                        key={status}
                        value={status}
                        onClick={() => {
                            showUpdateConfirm({ ids, status });
                        }}
                    >
                        <PoiStatusChip status={status} />
                    </MenuItem>
                ))}
            </DropDownMenu>
            <MutationProgressDialog
                mutationResult={result}
                finishedDialogContent={(
                    <>
                        <DialogContent>
                            <FormattedMessage id="poi-editor.batch-update.successful" values={{ rows }} />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    result.reset();
                                    reloadTable();
                                }}
                            >
                                <FormattedMessage id="message-dialog.ok" />
                            </Button>
                        </DialogActions>
                    </>
                )}
            />
            {confirmDialog}
        </Stack>
    );
};

export default PoiBatchOperations;
