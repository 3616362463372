import messagesEn from './en.json';
import messagesHu from './hu.json';

export type Messages = typeof messagesEn & typeof messagesHu;

// force TypeScript to check if every id is present in every language
const hu: Messages = messagesHu;
const en: Messages = messagesEn;

const messages = {
    hu,
    en,
};

export default messages;
