/* tslint:disable */
/* eslint-disable */
/**
 * POI service belső
 * realCity POI service belső API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpeningHours,
    OpeningHoursFromJSON,
    OpeningHoursFromJSONTyped,
    OpeningHoursToJSON,
    PoiCreatableDataAllOf,
    PoiCreatableDataAllOfFromJSON,
    PoiCreatableDataAllOfFromJSONTyped,
    PoiCreatableDataAllOfToJSON,
    PoiStatus,
    PoiStatusFromJSON,
    PoiStatusFromJSONTyped,
    PoiStatusToJSON,
    PoiUpdatableData,
    PoiUpdatableDataFromJSON,
    PoiUpdatableDataFromJSONTyped,
    PoiUpdatableDataToJSON,
} from './';




/**
 * 
 * @export
 * @interface PoiCreatableData
 */

export interface PoiCreatableData {
    /**
     * 
     * @type {PoiStatus}
     * @memberof PoiCreatableData
     */
    status: PoiStatus;
    /**
     * 
     * @type {string}
     * @memberof PoiCreatableData
     */
    type: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoiCreatableData
     */
    name: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoiCreatableData
     */
    description: { [key: string]: string; };
    /**
     * Üzemel
     * @type {boolean}
     * @memberof PoiCreatableData
     */
    operational: boolean;
    /**
     * 
     * @type {OpeningHours}
     * @memberof PoiCreatableData
     */
    openingHours?: OpeningHours;
    /**
     * 
     * @type {number}
     * @memberof PoiCreatableData
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof PoiCreatableData
     */
    longitude: number;
    /**
     * A POI egyedi azonosítója. Ha nincs megadva, automatikusan generálunk.
     * @type {string}
     * @memberof PoiCreatableData
     */
    id?: string;
}

export function PoiCreatableDataFromJSON(json: any): PoiCreatableData {
    return PoiCreatableDataFromJSONTyped(json, false);
}

export function PoiCreatableDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiCreatableData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        
        'status': PoiStatusFromJSON(json['status']),
        'type': json['type'],
        'name': json['name'],
        'description': json['description'],
        'operational': json['operational'],
        'openingHours': !exists(json, 'openingHours') ? undefined : OpeningHoursFromJSON(json['openingHours']),
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    } as any;
}

export function PoiCreatableDataToJSON(value?: PoiCreatableData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        
        'status': PoiStatusToJSON(value.status),
        'type': value.type,
        'name': value.name,
        'description': value.description,
        'operational': value.operational,
        'openingHours': OpeningHoursToJSON(value.openingHours),
        'latitude': value.latitude,
        'longitude': value.longitude,
        'id': value.id,
    };
}

